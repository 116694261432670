import * as React from "react";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import { Button, FormGroup, InputGroup } from "@blueprintjs/core";
import { Spacer } from "components/common";
import { useControlledInput } from "../../hooks";
import { useAuthActions } from "context/";
import { withPreventDefault } from "utils";
import Error from "./Error";

const notEmpty = (v) => v !== "";

const ResetPassword = (props) => {
  const location = useLocation();
  const history = useHistory();
  const { requestCode, passwordReset } = useAuthActions();

  const [hasCode, setHasCode] = React.useState(
    new URLSearchParams(location.search).get("code") || false
  );
  const [checkEmail, setCheckEmail] = React.useState(false);
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { valid: $email, ...email } = useControlledInput(notEmpty);
  const { valid: $code, ...code } = useControlledInput(notEmpty);
  const { valid: $password, ...password } = useControlledInput(
    (v) => v.length >= 8
  );
  const { valid: $confirmation, ...confirmation } = useControlledInput(
    (v) => $password && v === password.value
  );

  if (props.currentUser) {
    return <Redirect to="/folders" />;
  }

  const valid = $email && $code && $password && $confirmation;

  React.useEffect(() => {
    const query = new URLSearchParams(location.search);
    const searchEmail = query.get("email");
    const searchCode = query.get("code");

    if (searchEmail) {
      email.onChange({ target: { value: searchEmail } });
    }

    if (searchCode) {
      code.onChange({ target: { value: searchCode } });
    }
  }, [location.search]);

  if (checkEmail) {
    return (
      <div>
        <p>Check your email for a reset code.</p> <Spacer />
        <Button
          intent="primary"
          large
          fill
          type="button"
          onClick={() => {
            setCheckEmail(false);
            setHasCode(true);
          }}
        >
          Enter Reset Code
        </Button>
      </div>
    );
  }

  if (hasCode === false) {
    return (
      <form
        onSubmit={withPreventDefault((event) => {
          setLoading(true);
          requestCode(email.value).then(() => {
            setLoading(false);
            setCheckEmail(true);
          });
        })}
      >
        <FormGroup label="Login Email" labelFor="email-input">
          <InputGroup
            large
            id="email-input"
            placeholder="Login Email"
            {...email}
          />
        </FormGroup>
        <Button
          intent="primary"
          large
          fill
          disabled={$email === false}
          type="submit"
          loading={loading}
        >
          Send Reset Code
        </Button>
        <Spacer />
        <a onClick={() => setHasCode(true)}>I already have a code.</a>
      </form>
    );
  }

  return (
    <form
      onSubmit={withPreventDefault((event) => {
        setLoading(true);
        passwordReset(email.value, code.value, password.value).then(
          (result) => {
            if (result !== true) {
              setLoading(false);
              setError(result);
            }
          }
        );
      })}
    >
      <FormGroup label="Login Email" labelFor="email-input">
        <InputGroup
          large
          id="email-input"
          placeholder="Login Email"
          {...email}
        />
      </FormGroup>
      <FormGroup
        label="Reset Code"
        labelFor="code-input"
        helperText="Contact support@fybr.ca to obtain a reset code."
      >
        <InputGroup large id="code-input" placeholder="Reset Code" {...code} />
      </FormGroup>
      <FormGroup
        label="Password"
        labelFor="password-input"
        helperText="(minimum 8 characters)"
      >
        <InputGroup
          large
          id="password-input"
          type="password"
          placeholder="Password"
          {...password}
        />
      </FormGroup>
      <FormGroup label="Password Confirmation" labelFor="confirmation-input">
        <InputGroup
          large
          id="confirmation-input"
          type="password"
          placeholder="Password Confirmation"
          {...confirmation}
        />
      </FormGroup>
      <Error message={error} />
      <Button
        intent="primary"
        large
        fill
        disabled={valid == false}
        type="submit"
        loading={loading}
      >
        Reset Password
      </Button>
      {error && (
        <>
          <Spacer />
          <Button
            intent="primary"
            large
            fill
            type="button"
            onClick={() => history.push("/login")}
          >
            Login
          </Button>
        </>
      )}
    </form>
  );
};

export default ResetPassword;
