import * as React from "react";
import { css } from "@emotion/core";
import { Spinner } from "@blueprintjs/core";
import { Spacer, FybrLogo } from "./";

const Loading = () => {
  return (
    <section
      css={css`
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}
    >
      <FybrLogo size={96} />
      <Spacer size={4} />
      <Spinner />
    </section>
  );
};

export default Loading;
