import * as React from "react";
import { Route, useParams } from "react-router-dom";
import {
  PERMISSION_LEVEL_ADMIN,
  PERMISSION_LEVEL_EDIT,
  PERMISSION_LEVEL_VIEW,
  PERMISSION_LEVEL_EXTERNAL,
} from "../constants/";
import { useSinkState } from "context/";

const AccessStateContext = React.createContext();
const AccessActionsContext = React.createContext();

export const AccessProvider = ({ children }) => {
  const [state, setState] = React.useState({});

  const actions = {};

  return (
    <AccessStateContext.Provider value={state}>
      <AccessActionsContext.Provider value={actions}>
        {children}
      </AccessActionsContext.Provider>
    </AccessStateContext.Provider>
  );
};

export const useAccessState = () => {
  const context = React.useContext(AccessStateContext);

  if (context === undefined) {
    throw new Error("Must 'useAccessState' within a AccessProvider.");
  }

  return context;
};

export const useAccessActions = () => {
  const context = React.useContext(AccessActionsContext);

  if (context === undefined) {
    throw new Error("Must 'useAccessActions' within a AccessProvider.");
  }

  return context;
};

export const CheckAccess = (props) => {
  const { children, level, fallback, checkLocked } = props;
  const {
    sink: { surveys },
    selectedSurveyId,
  } = useSinkState();
  const survey = surveys.find((survey) => survey.id === selectedSurveyId);

  if (!survey) return null;

  if (checkLocked && (survey.locked_by_id || survey.locked_time)) {
    return fallback;
  }

  if (survey.level >= level) {
    return children;
  }

  return fallback;
};
export const CheckViewAccess = ({ children, ...props }) => (
  <CheckAccess level={PERMISSION_LEVEL_VIEW} {...props}>
    {children}
  </CheckAccess>
);

export const CheckEditAccess = ({ children, ...props }) => (
  <CheckAccess level={PERMISSION_LEVEL_EDIT} {...props}>
    {children}
  </CheckAccess>
);

export const CheckAdminAccess = ({ children, ...props }) => (
  <CheckAccess level={PERMISSION_LEVEL_ADMIN} {...props}>
    {children}
  </CheckAccess>
);

export const CheckFolderAccess = ({ id, children, fallback }) => {
  const {
    sink: { folders },
  } = useSinkState();

  const folder = folders.find((folder) => folder.id === id);

  if (!folder) return null;

  if (folder.level >= PERMISSION_LEVEL_ADMIN) {
    return children;
  }

  return fallback;
};
