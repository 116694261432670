import * as React from "react";
import { useLocation, useHistory } from "react-router-dom";

const SupportStateContext = React.createContext();
const SupportActionsContext = React.createContext();

export const SupportProvider = ({ children }) => {
  const history = useHistory();
  const [state, setState] = React.useState({ isOpen: false });
  const actions = {
    open: () => setState((p) => ({ ...p, isOpen: true })),
    close: () => {
      if (location.pathname.startsWith("/support")) {
        history.push("/folder");
      }
      setState((p) => ({ ...p, isOpen: false }));
    },
  };

  return (
    <SupportStateContext.Provider value={state}>
      <SupportActionsContext.Provider value={actions}>
        {children}
      </SupportActionsContext.Provider>
    </SupportStateContext.Provider>
  );
};

export const useSupportState = () => {
  const context = React.useContext(SupportStateContext);
  if (context === undefined) {
    throw new Error("Must 'useSupportState' within a SupportProvider.");
  }
  return context;
};

export const useSupportActions = () => {
  const context = React.useContext(SupportActionsContext);
  if (context === undefined) {
    throw new Error("Must 'useSupportActions' within a SupportProvider.");
  }
  return context;
};
