import { utcToZonedTime, format } from "date-fns-tz";

export const capitalize = (input: string) => {
  let buffer = "";

  for (let i = 0; i < input.length; i++) {
    /* always uppercase on the first character */
    if (i === 0) {
      buffer += input[i].toUpperCase();
      continue;
    }

    if (
      i + 1 !== input.length &&
      input[i].toUpperCase() === input[i] &&
      input[i] !== " "
    ) {
      buffer += ` ${input[i]}`;
      continue;
    }

    if (input[i - 1] === " ") {
      buffer += input[i].toUpperCase();
      continue;
    }

    buffer += input[i];
  }

  return buffer;
};

export const formatYYMMDD = (date: string | number) =>
  format(new Date(date), "yyyy-MM-dd");

export const formatYYMMDDandTime = (date: string | number) => {
  return format(new Date(date), "yyyy-MM-dd hh:mm aa");
};

export const formatTimeAndYYMMDD = (date: string | number) => {
  return format(new Date(date), "hh:mm aa yyyy-MM-dd");
};

export const formatWGS84 = (coordinates) => {
  if (coordinates === null) return null;

  if (Array.isArray(coordinates)) {
    coordinates = {
      latitude: coordinates[1],
      longitude: coordinates[0],
    };
  }

  return {
    latitude: `${Number(coordinates.latitude).toFixed(5)}° ${
      coordinates.latitude < 0 ? "S" : "N"
    }`,
    longitude: `${Number(coordinates.longitude).toFixed(5)}° ${
      coordinates.longitude > 0 ? "W" : "E"
    }`,
  };
};
