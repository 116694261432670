import * as React from "react";
import { css } from "@emotion/core";
import { Spacer } from "components/common";

const Error = ({ message }) =>
  message ? (
    <div
      css={css`
        margin-bottom: 1rem;
        color: var(--danger);
        max-width: 360px;
      `}
    >
      {message}
    </div>
  ) : (
    <Spacer size={2} />
  );

export default Error;
