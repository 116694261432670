import * as React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Button, InputGroup, FormGroup } from "@blueprintjs/core";
import { Spacer } from "components/common";
import { useControlledInput } from "hooks/";
import { useAuthActions } from "context/";
import Error from "./Error";
import { withPreventDefault } from "utils";

/* TODO: come back and make these validations better */

const Login = () => {
  const history = useHistory();
  const { login } = useAuthActions();
  const { valid: $email, ...email } = useControlledInput((v) => v !== "");
  const { valid: $password, ...password } = useControlledInput((v) => v !== "");
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const redirect = params.get("redirect");

  return (
    <form
      onSubmit={withPreventDefault((event) => {
        if ($email && $password) {
          setLoading(true);
          login(email.value, password.value).then((result) => {
            if (result !== true) {
              setLoading(false);
              setError(result);
            } else {
              history.push(redirect || "/folders", null);
            }
          });
        }
      })}
    >
      <FormGroup label="Login Email" labelFor="email-input">
        <InputGroup
          large
          id="email-input"
          placeholder="Login Email"
          autoComplete="email"
          {...email}
        />
      </FormGroup>
      <FormGroup label="Password" labelFor="password-input">
        <InputGroup
          large
          id="password-input"
          type="password"
          placeholder="Password"
          autoComplete="current-password"
          {...password}
        />
      </FormGroup>
      <Error message={error} />
      <Button type="submit" intent="primary" large fill loading={loading}>
        Login
      </Button>
      <Spacer />
      <Link to="/resetpassword">Forgot Password?</Link>
    </form>
  );
};

export default Login;
