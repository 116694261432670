import { useState } from "react";

export default (validation, initial = "") => {
  const [state, setState] = useState(initial);
  return {
    onChange: (event) => setState(event.target.value),
    value: state,
    reset: () => setState(initial),
    valid: validation ? validation(state) : true,
  };
};
