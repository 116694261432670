import * as React from "react";
import { css } from "@emotion/core";

const ScrollView = ({ children }) => {
  return (
    <section
      css={css`
        flex: 1;
        position: relative;
        overflow-y: auto;
      `}
    >
      {children}
    </section>
  );
};

export default ScrollView;
