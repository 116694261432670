import * as React from "react";
import { withRouter } from "react-router-dom";
import ApiAuthenticate from "../api/authenticate";

const AuthStateContext = React.createContext();
const AuthActionsContext = React.createContext();

const reducer = (state, action) => {
  const { type, user, error } = action;

  if (type === "LOGIN_SUCCESS") {
    return {
      currentUser: user,
      isAuthenticated: true,
      isPendingAuthentication: false,
      error: null,
    };
  }

  if (type === "LOGIN_FAILURE") {
    return {
      currentUser: null,
      isAuthenticated: false,
      isPendingAuthentication: false,
      error,
    };
  }

  if (type === "LOGOUT") {
    return {
      currentUser: null,
      isAuthenticated: false,
      isPendingAuthentication: false,
      error: null,
    };
  }

  throw new Error(`Auth reducer does not handle this type: ${type}`);
};

export const AuthProvider = withRouter(({ children, history, ...rest }) => {
  const [state, dispatch] = React.useReducer(reducer, {
    currentUser: null,
    isAuthenticated: false,
    isPendingAuthentication: document.cookie.includes("fybr-cloud"),
    error: null,
  });

  React.useEffect(() => {
    if (!state.currentUser && state.isPendingAuthentication) {
      ApiAuthenticate.whoami().then((response) => {
        if (response.error) {
          document.cookie =
            "fybr-cloud" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
          dispatch({ type: "LOGOUT" });
          history.push("/login");
          return;
        }
        if (response.data && response.data.user) {
          dispatch({ type: "LOGIN_SUCCESS", user: response.data.user });
        } else {
          dispatch({ type: "LOGIN_FAILURE" });
        }
      });
    }
  }, [state.currentUser, state.isPendingAuthentication]);

  const actions = {
    login: async (email, password) => {
      if (!email || !password) {
        throw new Error("This should be validated before submission");
      }

      const response = await ApiAuthenticate.login(email, password);

      if (response.error) {
        return response.error.message;
      }

      if (response.data.user) {
        dispatch({ type: "LOGIN_SUCCESS", user: response.data.user });
        return true;
      }

      return false;
    },
    logout: async () => {
      await ApiAuthenticate.logout();
      dispatch({ type: "LOGOUT" });
      return true;
    },
    requestCode: async (email: string): Promise<boolean> => {
      const response = await ApiAuthenticate.requestCode(email);

      if (response.error) {
        return response.error.message;
      }

      if (response.data || response.message) {
        return true;
      }

      return false;
    },
    passwordReset: async (
      email: string,
      resetCode: string,
      newPassword: string
    ): Promise<boolean> => {
      const response = await ApiAuthenticate.resetPassword(
        email,
        resetCode,
        newPassword
      );

      if (response.error) {
        return response.error.message;
      }

      if (response.data || response.message) {
        history.push("/login");
        return true;
      } else {
        console.warn("unknown error:", response);
      }

      return false;
    },
  };

  return (
    <AuthStateContext.Provider value={state}>
      <AuthActionsContext.Provider value={actions}>
        {children}
      </AuthActionsContext.Provider>
    </AuthStateContext.Provider>
  );
});

export const useAuthState = () => {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error("Must 'useAuthState' within an AuthProvider.");
  }
  return context;
};

export const useAuthActions = () => {
  const context = React.useContext(AuthActionsContext);
  if (context === undefined) {
    throw new Error("Must 'useAuthActions' within an AuthProvider.");
  }
  return context;
};
