import { useEffect } from "react";

export default function useRefreshOnFocus(cb) {
  useEffect(() => {
    if (cb) {
      const listener = (event) => {
        if (
          event.type === "visibilitychange" &&
          document.visibilityState === "visible"
        ) {
          cb();
        }

        if (event.type === "focus") {
          cb();
        }
      };

      document.addEventListener("visibilitychange", listener);
      window.addEventListener("focus", listener);

      cb();

      return () => {
        document.removeEventListener("visibilitychange", listener);
        window.removeEventListener("focus", listener);
      };
    }
  }, [cb]);
}
