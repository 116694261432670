(window as any).debug = false;
if ((window as any).debug) {
  console.warn(
    "⚠️ DEBUG MODE IS ON! You should set `window.debug` to false in the application's index file before launching this anywhere."
  );
}

import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./components/App";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./context/auth";
import * as Sentry from "@sentry/react";

import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

if (process.env.REACT_APP_SENTRY_DSN_URL) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN_URL,
  });
}

const Entry = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  );
};

ReactDOM.render(<Entry />, document.getElementById("root"));

/*
  <_<
  >_>
*/

if ((window as any).debug) {
  let titleIncrement = 0;
  let titleAscend = true;
  let titleMax = 4;
  setInterval(() => {
    document.title = "🐛".repeat(titleIncrement) + "DEBUG MODE";
    if (titleAscend) {
      if (titleIncrement === titleMax) titleAscend = false;
      else titleIncrement++;
    } else {
      if (titleIncrement === 0) titleAscend = true;
      else titleIncrement--;
    }
  }, 500);
}
