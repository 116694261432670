import * as React from "react";
import {
  Button,
  Classes,
  H3,
  Intent,
  Overlay,
  TextArea,
  FormGroup,
  InputGroup,
  FileInput,
  Callout,
} from "@blueprintjs/core";
import { Spacer } from "components/common";

const TEN_MB = 10 * 1024 * 1024;

interface ErrorState {
  intent: Intent | "";
  message: string;
}

interface Props {
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

function FormAttachment({ files, setFiles }: Props) {
  const [error, setError] = React.useState<ErrorState>({
    intent: "",
    message: "",
  });

  const handleFiles = (files: File[]) => {
    if (files.length > 10) {
      setError({
        intent: "danger",
        message: "Too many attachments added, the limit is 10 attachments.",
      });
      return;
    }

    if (files.length > 0) {
      const allowed = files.filter((file) => file.size <= TEN_MB);
      const rejected = files.filter((file) => file.size > TEN_MB);

      if (rejected.length > 0) {
        setError({
          intent: "warning",
          message: "Some files were too large to include.",
        });
      }

      setFiles(allowed);
    }
  };

  return (
    <>
      <FormGroup
        helperText={`${
          files.length > 0
            ? `${files.length} file${files.length > 1 ? "s" : ""} added. `
            : ""
        }Limit of 10 files no larger than 10MB each.`}
        label="Attachments"
        labelFor="attachments-input"
        labelInfo="(optional)"
      >
        <FileInput
          text={
            files.length > 0
              ? files.map((file) => file.name).join(", ")
              : "Attachments..."
          }
          buttonText="Browse"
          onInputChange={(event: React.FormEvent<HTMLInputElement>) =>
            handleFiles(Array.from(event.target.files))
          }
          inputProps={{ multiple: true }}
          fill
        />
      </FormGroup>
      {error.intent && error.message && (
        <>
          <Callout intent={error.intent}>{error.message}</Callout>
          <Spacer />
        </>
      )}
    </>
  );
}

export default FormAttachment;
