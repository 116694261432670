export default class Cannot {
  public problem: string;
  public class: typeof Cannot;
  public static react;

  constructor(problem: string) {
    this.problem = problem;
    this.class = Cannot;
  }

  public inspect() {
    // TODO: this.select(); Should work with this.setFeatureState
    Cannot.react.setState({ inspectorModel: this });
  }

  public static connect(getState, setState): void {
    this.react = { getState, setState };
  }
}
