import * as React from "react";

export default () => {
  const [expanded, setExpanded] = React.useState({
    root: true,
  });

  return {
    isExpanded: (id) => expanded[id] !== undefined && expanded[id],
    toggle: (id) =>
      setExpanded((prev) => ({
        ...prev,
        [id]: prev[id] === undefined ? true : !prev[id],
      })),
    collapse: (id) => setExpanded((prev) => ({ ...prev, [id]: false })),
    expand: (id) => setExpanded((prev) => ({ ...prev, [id]: true })),
    collapseAll: () => setExpanded({ root: true }),
    expandAll: (ids) => {
      setExpanded((prev) => ({
        ...ids.reduce((all, id) => ({ ...all, [id]: true }), {}),
        root: true,
      }));
    },
  };
};
