const allowedKeys = [
  "notify_order_started",
  "notify_order_complete",
  "notify_survey_locked",
] as const;

type NotificationTypes = (typeof allowedKeys)[number];

class ApiUser {
  public static updateUser(fields: { [K in NotificationTypes]?: boolean }) {
    const allowedFields: {
      notify_order_started?: boolean;
      notify_order_complete?: boolean;
      notify_survey_locked?: boolean;
    } = allowedKeys.reduce(
      (allowed, key) =>
        fields[key] !== undefined
          ? { ...allowed, [key]: fields[key] }
          : allowed,
      {}
    );

    return fetch(`/api/v1/user`, {
      method: "PUT",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(allowedFields),
    });
  }
}

export default ApiUser;
