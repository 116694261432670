import { PimentoPost, PimentoDelete } from "./apiUtils";

import Survey from "../models/Survey";
import Layer from "../models/Layer";
import Annotation from "../models/Annotation";
import UndoArchiveManager from "../services/UndoArchiveManager";

import { getNextNumericalLabel } from "../utils";

interface IAnnotationLayerFields {
  label?: string;
  color?: string;
}

class ApiLayers {
  public static react: any;
  public loading: boolean;

  public static createAnnotationLayer(
    surveyId: number,
    fields: IAnnotationLayerFields = { label: "" }
  ): Promise<any> {
    const createAnnotationLayerRoute = `/api/v1/surveys/${surveyId}/layers`;
    const annoLayerLabels = Survey.find(surveyId)
      .layers.filter((l) => l.type === "annotation" && !l.archived)
      .map((l) => l.label);
    const label =
      fields.label ||
      getNextNumericalLabel("New Markup Layer", annoLayerLabels);
    return fetch(createAnnotationLayerRoute, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...fields, label, type: "annotation" }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.data.layer) {
          const newLayer = new Layer(res.data.layer, ApiLayers.react.getState);

          // Rare case of the setState second parameter:
          // Callback that runs after the (async) setState has been performed.
          ApiLayers.react.setState(
            (prev) => ({ layers: [...prev.layers, newLayer] }),
            () => {
              newLayer.inspect();
            }
          );
          return newLayer;
        }
      })
      .catch((err) => console.error(err));
  }

  public static updateAnnotationLayer(
    layerId: number,
    fields: IAnnotationLayerFields
  ): Promise<any> {
    const whitelist = ["label", "color"];
    const whitelistedFields = whitelist.reduce((obj, next) => {
      if (fields[next] === undefined) return obj;
      obj[next] = fields[next];
      return obj;
    }, {});

    const updateAnnotationLayerRoute = `/api/v1/layers/${layerId}`;
    return fetch(updateAnnotationLayerRoute, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(whitelistedFields),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.data.layer) {
          const updatedLayer = Layer.find(res.data.layer.id);
          if (updatedLayer) {
            updatedLayer.setAttributes(res.data.layer);
            updatedLayer.poke();
          } else {
            console.warn(
              `⚠️ Problem with server response while updating layer. Server responded with: ${JSON.stringify(
                res
              )}`
            );
          }
        }
      })
      .catch((err) => console.error(err));
  }

  public static archiveAnnotationLayer(layerId: number): Promise<any> {
    return PimentoPost(`/api/v1/layers/${layerId}/archive`)
      .then((res) => {
        if (res.data.layer) {
          const archivedLayer = Layer.find(res.data.layer.id);
          if (archivedLayer) {
            archivedLayer.setAttributes(res.data.layer);
            UndoArchiveManager.add("layer", archivedLayer.id);
          } else {
            console.warn(
              `⚠️ Problem with server response while archiving layer. Server responded with: ${JSON.stringify(
                res
              )}`
            );
          }

          res.data.annotations.forEach((a) => {
            const annoModel = Annotation.find(a.id);
            if (!annoModel) return;
            annoModel.setAttributes(a);
          });
        }
      })
      .catch((err) => console.error(err));
  }

  public static unarchiveAnnotationLayer(layerId: number): Promise<any> {
    return PimentoPost(`/api/v1/layers/${layerId}/unarchive`)
      .then((res) => {
        if (res.data.layer) {
          const archivedLayer = Layer.find(res.data.layer.id);

          if (archivedLayer) {
            archivedLayer.setAttributes(res.data.layer);
            UndoArchiveManager.remove("layer", archivedLayer.id);
          } else {
            console.warn(
              `⚠️ Problem with server response while unarchiving layer. Server responded with: ${JSON.stringify(
                res
              )}`
            );
          }

          res.data.annotations.forEach((a) => {
            const annoModel = Annotation.find(a.id);
            if (!annoModel) return;
            annoModel.setAttributes(a);
          });
        }
      })
      .catch((err) => console.error(err));
  }

  public static createAttributeLabel(
    layerId: number,
    label: string
  ): Promise<any> {
    return PimentoPost(
      `/api/v1/layers/${layerId}/attributes`,
      { label },
      {
        error: `Server error while trying to create attribute label for layer with ID <<${layerId}>>:`,
      }
    )
      .then((res) => {
        if (res.data.attribute) {
          const updatedLayer = Layer.find(res.data.attribute.layer_id);

          if (updatedLayer) {
            const annotations = updatedLayer.annotations;

            for (const annotation of annotations) {
              annotation.addUserAttribute(
                res.data.attribute.id,
                res.data.attribute.label
              );
            }

            updatedLayer.addAttributeLabel(res.data.attribute);
          }

          return updatedLayer;
        }
      })
      .catch((err) => console.error(err));
  }

  public static removeAttributeLabel(attributeId: number): Promise<any> {
    return PimentoDelete(`/api/v1/attributes/${attributeId}`)
      .then((res) => {
        if (res.data.attribute) {
          const updatedLayer = Layer.find(res.data.attribute.layer_id);

          if (updatedLayer) {
            const annotations = updatedLayer.annotations;

            for (const annotation of annotations) {
              annotation.removeUserAttribute(res.data.attribute.id);
            }

            updatedLayer.removeAttributeLabel(res.data.attribute.id);
          }

          return updatedLayer;
        }
      })
      .catch((err) => console.error(err));
  }

  public static connect(getState, setState) {
    this.react = { getState, setState };
  }
}

export default ApiLayers;
