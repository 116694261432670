import * as React from "react";
import { css } from "@emotion/core";

const base = css`
  width: calc(3 * var(--block-size));
  height: calc(3 * var(--block-size));
  position: relative;

  & > .tl {
    transform: translate(0, 0);
  }
  & > .tr {
    transform: translate(calc(2 * var(--block-size)), 0);
  }
  & > .cm {
    transform: translate(var(--block-size), var(--block-size));
  }
  & > .bl {
    transform: translate(0, calc(2 * var(--block-size)));
  }

  &:hover > .tl {
    transition: background-color 0.8s ease-in-out 0.1s;
  }
  &:hover > .tr {
    transition: background-color 0.8s ease-in-out 0s;
  }
  &:hover > .cm {
    transition: background-color 0.8s ease-in-out 0.3s;
  }
  &:hover > .bl {
    transition: background-color 0.8s ease-in-out 0.5s;
  }
`;

const FybrLogo = ({
  size = 45,
  dark = false,
}: {
  size?: number;
  dark?: boolean;
}) => {
  const styles = css`
    ${base}

    & > div {
      width: var(--block-size);
      height: var(--block-size);
      position: absolute;

      background-color: ${dark ? "var(--twilight)" : "var(--fybr-blue)"};
      transition: 0.4s;
    }

    &:hover > div {
      background-color: ${dark ? "var(--fybr-blue)" : "var(--twilight)"};
    }
  `;

  return (
    <figure
      css={css`
        --block-size: ${size / 3}px;
        ${styles}
      `}
    >
      {/* 
        'as any' here is a workaround for CSSProperties type not supporting 
        custom CSS attributes (i.e. CSS variables). 
        See: https://github.com/DefinitelyTyped/DefinitelyTyped/pull/5089  
      */}
      <div className="tl"></div>
      <div className="tr"></div>
      <div className="cm"></div>
      <div className="bl"></div>
    </figure>
  );
};

export default FybrLogo;
