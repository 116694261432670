import * as React from "react";
import * as mapboxgl from "mapbox-gl";
import bbox from "@turf/bbox";
import { useMapState, useSinkActions, useSinkState } from "context/";

import {
  getSourceFrom,
  addSourceTo,
  addStrokeTo,
  addFillTo,
  addLabelTo,
  addCircleTo,
  moveLabelsOn,
  moveOutlineOn,
} from "utils/mapping";

const AnnotationStateContext = React.createContext(null);
const AnnotationActionsContext = React.createContext(null);

const setFeature = (map, id, source) => {
  const set = (state) =>
    map.setFeatureState(
      {
        source: source.id,
        id,
      },
      state
    );

  return {
    selected: () => set({ selected: true }),
    deselected: () => set({ selected: false }),
    focused: () => set({ hover: true }),
    unfocused: () => set({ hover: false }),
  };
};

const setLayout = (map: any, id: number, source: any, type?: string) => {
  if (source === undefined) return null;

  const set = (key, value) => {
    if (type) {
      if (map.getLayer(`${source.id}-${type}`)) {
        map.setLayoutProperty(`${source.id}-${type}`, key, value);
      }
    } else {
      if (map.getLayer(`${source.id}-fill`)) {
        map.setLayoutProperty(`${source.id}-fill`, key, value);
      }
      if (map.getLayer(`${source.id}-line`)) {
        map.setLayoutProperty(`${source.id}-line`, key, value);
      }
    }
  };

  return {
    show: () => set("visibility", "visible"),
    hide: () => set("visibility", "none"),
  };
};

export const CHANGE_FOCUSED = "CHANGE_FOCUSED";
export const CHANGE_SELECTED = "CHANGE_SELECTED";

type AnnotationEventType = "CHANGE_FOCUSED" | "CHANGE_SELECTED";

export const AnnotationProvider = ({ children, annotations }) => {
  const { map } = useMapState();
  const { selectedSurveyId } = useSinkState();
  const { stopInspecting } = useSinkActions();

  const subscriptions = React.useRef<{
    [key: number]: ((type: AnnotationEventType, value: any) => {})[];
  }>({});
  const selectedAnnotations = React.useRef<{ [key: number]: any }>({});
  const focusedAnnotations = React.useRef<{ [key: number]: any }>({});

  const moveLabels = moveLabelsOn(map);
  const moveOutline = moveOutlineOn(map);
  const getSource = getSourceFrom(map);
  const addSource = addSourceTo(map);
  const addStroke = addStrokeTo(map);
  const addFill = addFillTo(map);
  const addLabel = addLabelTo(map);
  const addCircle = addCircleTo(map);

  React.useEffect(() => {
    moveLabels(annotations.map((annotation) => annotation.id));
  }, [annotations]);

  const isSelected = (id: number) =>
    selectedAnnotations.current[id] !== undefined &&
    selectedAnnotations.current[id] !== null;

  const isFocused = (id: number) =>
    focusedAnnotations.current[id] !== undefined &&
    focusedAnnotations.current[id] !== null;

  const subscribe = (id: number, fn: () => null) => {
    if (subscriptions.current[id] === undefined) {
      subscriptions.current[id] = [];
    }

    subscriptions.current[id].push(fn);
  };

  const unsubscribe = (id: number, fn: () => null) => {
    if (subscriptions.current[id]) {
      subscriptions.current[id] = subscriptions.current[id].filter(
        (handler) => handler !== fn
      );
    }
  };

  const fire = (id: number, type: AnnotationEventType, value: any) => {
    if (subscriptions.current[id] === undefined) return;

    Object.entries(subscriptions.current[id]).forEach(([_, fn]) =>
      fn(type, value)
    );
  };

  const select = React.useCallback(
    (annotationId: number) => {
      Object.entries(selectedAnnotations.current).forEach(([key, fn]) => {
        fn();
        fire(Number(key), "CHANGE_SELECTED", false);
      });

      const source = getSource(annotationId);

      if (source) {
        selectedAnnotations.current = {
          [annotationId]: () =>
            setFeature(map, annotationId, source).deselected(),
        };

        const existingFeatures: { [key: string]: boolean } = {};
        const features = map
          .queryRenderedFeatures()
          .filter((layer) => {
            if (existingFeatures[layer.id]) {
              return false;
            }

            existingFeatures[layer.id] = true;

            return true;
          })
          .map((feature) => feature.id);

        const feature = annotations.find(
          (annotation) => annotation.id === annotationId
        );

        if (feature && features.includes(annotationId) === false) {
          const all = bbox(feature.geometry);
          map.fitBounds(
            [
              [all[0], all[1]],
              [all[2], all[3]],
            ],
            { animate: true, padding: 100 }
          );
        }

        setFeature(map, annotationId, source).selected();
      } else {
        selectedAnnotations.current = { [annotationId]: () => null };
      }

      moveOutline([annotationId]);
      moveLabels([annotationId]);

      fire(annotationId, "CHANGE_SELECTED", true);
    },
    [annotations, getSource, map, moveLabels, moveOutline]
  );

  const deselect = React.useCallback(() => {
    Object.entries(selectedAnnotations.current).forEach(([key, fn]) => {
      fn();
      fire(Number(key), "CHANGE_SELECTED", false);
    });

    selectedAnnotations.current = {};
  }, []);

  const focus = (annotationId: number) => {
    if (isFocused(annotationId)) return;

    const source = getSource(annotationId);

    if (source) {
      setFeature(map, annotationId, source).focused();
      setLayout(map, annotationId, source, "label").show();
      focusedAnnotations.current[annotationId] = () =>
        setFeature(map, annotationId, source).unfocused();
    } else {
      focusedAnnotations.current[annotationId] = () => null;
    }

    moveOutline([annotationId]);
    moveLabels([annotationId]);

    fire(annotationId, "CHANGE_FOCUSED", true);
  };

  const unfocus = (annotationId: number) => {
    if (isFocused(annotationId) === false) return;

    if (focusedAnnotations.current[annotationId]) {
      const source = getSource(annotationId);
      if (source) {
        setLayout(map, annotationId, source, "label").hide();
      }
      focusedAnnotations.current[annotationId]();
      focusedAnnotations.current[annotationId] = null;
    }

    const selected = Object.keys(selectedAnnotations.current);
    if (selected.length > 0) {
      moveOutline(selected);
    }

    fire(annotationId, "CHANGE_FOCUSED", false);
  };

  React.useEffect(() => {
    if (map) {
      const click = (event) => {
        if (selectedSurveyId === null) return;

        const features = map
          .queryRenderedFeatures(
            new mapboxgl.Point(event.point.x, event.point.y)
          )
          .filter(
            (feature: { id: number; source: string }) =>
              feature.id && feature.source !== "composite"
          )
          .sort((a: { id: number }, b: { id: number }) => b.id - a.id);

        const selectedKeys = Object.keys(selectedAnnotations.current);
        const selectedKey =
          selectedKeys.length > 0 ? Number(selectedKeys[0]) : null;
        const selectedIndex = features.findIndex(
          (feature: { id: number }) => feature.id === selectedKey
        );

        const next =
          selectedIndex > -1 && selectedIndex + 1 < features.length
            ? features[selectedIndex + 1]
            : features[0];

        if (next) {
          if (selectedAnnotations.current[next.id]) return;
          const annotation = annotations.find(
            (annotation) => annotation.id === next.id
          );

          if (annotation) {
            annotation.inspect();
          }

          select(next.id);
          return;
        }

        deselect();
        stopInspecting();
      };

      map.on("click", click);

      return () => map.off("click", click);
    }
  }, [map, annotations, deselect, select, stopInspecting, selectedSurveyId]);

  const actions = {
    subscribe,
    unsubscribe,
    select,
    deselect,
    focus,
    unfocus,
    addPolygon: (id, label, geo, paint, visible) => {
      const source = getSource(id) || addSource(id, { geo, label });

      const labelLayer = addLabel(source.id);
      const fillLayer = addFill(source.id, { paint });
      const strokeLayer = addStroke(source.id, { paint });

      const mousemove = (event) => {
        if (event.features.length > 0) {
          focus(id);
        }
      };
      map.on("mousemove", fillLayer.id, mousemove);

      const mouseleave = () => {
        if (focusedAnnotations.current[id]) {
          unfocus(id);
        }
      };
      map.on("mouseleave", fillLayer.id, mouseleave);

      setLayout(map, id, source)[visible ? "show" : "hide"]();

      return {
        destroy: () => {
          map.removeLayer(labelLayer.id);
          map.removeLayer(strokeLayer.id);
          map.removeLayer(fillLayer.id);
          map.removeSource(source.id);
          map.removeSource(`${source.id}-center`);
          map.off("mousemove", fillLayer.id, mousemove);
          map.off("mouseleave", fillLayer.id, mouseleave);

          if (selectedAnnotations.current[id]) {
            selectedAnnotations.current = { [id]: () => null };
          }
        },
        setVisibility: (visible) => {
          setLayout(map, id, source)[visible ? "show" : "hide"]();
        },
      };
    },
    addLineString: (id, label, geo, paint, visible) => {
      const source = getSource(id) || addSource(id, { geo, label });

      const strokeLayer = addStroke(source.id, { paint });
      const labelLayer = addLabel(source.id);

      const mousemove = (event) => {
        if (event.features.length > 0) {
          focus(id);
        }
      };
      map.on("mousemove", strokeLayer.id, mousemove);

      const mouseleave = () => {
        if (focusedAnnotations.current[id]) {
          unfocus(id);
        }
      };
      map.on("mouseleave", strokeLayer.id, mouseleave);

      setLayout(map, id, source)[visible ? "show" : "hide"]();

      return {
        destroy: () => {
          map.removeLayer(labelLayer.id);
          map.removeLayer(strokeLayer.id);
          map.removeSource(source.id);
          map.removeSource(`${source.id}-center`);
          map.off("mousemove", strokeLayer.id, mousemove);
          map.off("mouseleave", strokeLayer.id, mouseleave);

          if (selectedAnnotations.current[id]) {
            selectedAnnotations.current = { [id]: () => null };
          }
        },
        setVisibility: (visible) => {
          setLayout(map, id, source)[visible ? "show" : "hide"]();
        },
      };
    },
    addCircle: (id, label, geo, paint, visible) => {
      const source = getSource(id) || addSource(id, { geo, label });

      const labelLayer = addLabel(source.id);
      const circleLayer = addCircle(source.id, { paint });

      const mousemove = (event) => {
        if (event.features.length > 0) {
          focus(id);
        }
      };
      map.on("mousemove", circleLayer.id, mousemove);

      const mouseleave = () => {
        if (focusedAnnotations.current[id]) {
          unfocus(id);
        }
      };
      map.on("mouseleave", circleLayer.id, mouseleave);

      setLayout(map, id, source)[visible ? "show" : "hide"]();

      return {
        destroy: () => {
          map.removeLayer(labelLayer.id);
          map.removeLayer(circleLayer.id);
          map.removeSource(source.id);
          map.removeSource(`${source.id}-center`);

          map.off("mousemove", circleLayer.id, mousemove);
          map.off("mouseleave", circleLayer.id, mouseleave);

          if (selectedAnnotations.current[id]) {
            selectedAnnotations.current = { [id]: () => null };
          }
        },
        setVisibility: (visible) => {
          setLayout(map, id, source)[visible ? "show" : "hide"]();
        },
      };
    },
  };

  return (
    <AnnotationStateContext.Provider value={{ isSelected, isFocused }}>
      <AnnotationActionsContext.Provider value={actions}>
        {children}
      </AnnotationActionsContext.Provider>
    </AnnotationStateContext.Provider>
  );
};

export const useAnnotationState = () => {
  const context = React.useContext(AnnotationStateContext);

  if (!context) {
    throw new Error("Must 'useAnnotationState' within a AnnotationProvider.");
  }

  return context;
};

export const useAnnotationActions = () => {
  const context = React.useContext(AnnotationActionsContext);

  if (!context) {
    throw new Error("Must 'useAnnotationActions' within a AnnotationProvider.");
  }

  return context;
};

export const useAnnotationSubscription = (annotation) => {
  const { isSelected, isFocused } = useAnnotationState();
  const { subscribe, unsubscribe } = useAnnotationActions();

  const [selected, setSelected] = React.useState(() =>
    isSelected(annotation.id)
  );
  const [focused, setFocused] = React.useState(() => isFocused(annotation.id));

  React.useEffect(() => {
    const fn = (type: AnnotationEventType, value: any) => {
      if (type === CHANGE_SELECTED) {
        setSelected(value);
      }
      if (type === CHANGE_FOCUSED) {
        setFocused(value);
      }
    };

    subscribe(annotation.id, fn);

    return () => unsubscribe(annotation.id, fn);
  }, [annotation, subscribe, unsubscribe]);

  return {
    focused,
    selected,
  };
};
