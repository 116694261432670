import * as React from "react";
import { unitConversionMethods } from "../utils";
import { useSinkState } from "context/";

const UnitsStateContext = React.createContext(null);
const UnitsActionsContext = React.createContext(null);

const fetchUnitConversions = () => {
  return fetch(`/api/v1/units`, {
    method: "GET",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());
};

export const UnitProvider = ({ children }) => {
  const {
    sink: {
      organization: { units },
    },
  } = useSinkState();
  const [conversions, setConversions] = React.useState([]);
  const [unit, setUnit] = React.useState(units || "metric");

  const conversionsTable = React.useMemo(
    () =>
      conversions.reduce(
        (table, conversion) => ({ ...table, [conversion.type]: conversion }),
        {}
      ),
    [conversions]
  );
  const state = {
    conversions,
    unit,
  };

  const actions = {
    convert: (
      value,
      conversionUnit,
      includeSuffix = true,
      includeSeparator = true
    ) => {
      if (["WGS84", "text"].includes(conversionUnit)) {
        return value;
      }

      const separate = includeSeparator
        ? (input) => {
            if (typeof input === "string") {
              return parseFloat(input).toLocaleString("en", {
                minimumFractionDigits: 1,
                maximumFractionDigits: 5,
              });
            }

            if (typeof input === "number") {
              return input.toLocaleString("en", {
                minimumFractionDigits: 1,
                maximumFractionDigits: 5,
              });
            }
            return input;
          }
        : (input) => input;

      if (!conversionUnit || !conversionsTable[conversionUnit])
        return separate(value.toFixed(1));

      if (unit === "metric") {
        return `${separate(
          value.toFixed(conversionsTable[conversionUnit].metric_precision)
        )} ${
          includeSuffix
            ? conversionsTable[conversionUnit].metric_unit.replace(/\^/, "")
            : ""
        }`;
      }

      const inputUnit = conversionsTable[conversionUnit].metric_unit;

      if (unitConversionMethods[inputUnit]) {
        return `${separate(
          unitConversionMethods[inputUnit](
            value,
            conversionsTable[conversionUnit].imperial_precision
          )
        )} ${
          includeSuffix
            ? conversionsTable[conversionUnit].imperial_unit.replace(/\^/, "")
            : ""
        }`;
      }

      return separate(value);
    },
    setUnit,
  };

  React.useEffect(() => {
    fetchUnitConversions().then(({ conversions }) =>
      setConversions(conversions)
    );
  }, []);

  if (conversions.length === 0) return null;

  return (
    <UnitsStateContext.Provider value={state}>
      <UnitsActionsContext.Provider value={actions}>
        {children}
      </UnitsActionsContext.Provider>
    </UnitsStateContext.Provider>
  );
};

export const useUnitState = () => {
  const context = React.useContext(UnitsStateContext);

  if (context === undefined) {
    throw new Error("Must 'useUnitState' within a UnitProvider.");
  }

  return context;
};

export const useUnitActions = () => {
  const context = React.useContext(UnitsActionsContext);

  if (context === undefined) {
    throw new Error("Must 'useUnitActions' within a UnitProvider.");
  }

  return context;
};
