import * as React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Link } from "react-router-dom";
import {
  Alignment,
  Button,
  ButtonGroup,
  NavbarDivider,
  NavbarGroup,
  Popover,
  Card,
  RadioGroup,
  Radio,
  Switch,
  PopoverInteractionKind,
  FormGroup,
} from "@blueprintjs/core";
import { useAuthActions } from "context/auth";
import {
  useSinkState,
  useSupportActions,
  useUnitState,
  useUnitActions,
} from "context";
import ApiUser from "../../api/user";

const NavButton = ({ to, ...props }) => {
  const StyledLink = styled(Link)`
    color: inherit !important;
    &:hover {
      text-decoration: none;
    }
  `;

  if (to) {
    return (
      <StyledLink to={to}>
        <Button {...props} />
      </StyledLink>
    );
  }

  return <Button {...props} />;
};

const AuthenticatedHeader = () => {
  const { logout } = useAuthActions();
  const { open: openSupport } = useSupportActions();
  const {
    sink: { user },
  } = useSinkState();
  const { unit } = useUnitState();
  const { setUnit } = useUnitActions();

  const [notifications, setNotifications] = React.useState<{
    notify_order_started: boolean;
    notify_order_complete: boolean;
    notify_survey_locked: boolean;
  }>({
    notify_order_started: user.notify_order_started,
    notify_order_complete: user.notify_order_complete,
    notify_survey_locked: user.notify_survey_locked,
  });

  const updateNotification = async (fields: {
    notify_order_started?: boolean;
    notify_order_complete?: boolean;
    notify_survey_locked?: boolean;
  }) => {
    const response = await ApiUser.updateUser(fields);

    if (response.ok === false) {
      setNotifications((prev) => ({
        ...prev,
        ...Object.entries(fields).reduce((flipped, [key, value]) => {
          flipped[key] = !value;
          return flipped;
        }, {}),
      }));
    }
  };

  return (
    <NavbarGroup align={Alignment.RIGHT}>
      <ButtonGroup>
        <NavButton
          onClick={() => window.open("/knowledge", "_blank")}
          icon="help"
        />
        <NavButton onClick={openSupport} icon="phone" />
        <Popover interactionKind={PopoverInteractionKind.HOVER}>
          <NavButton icon="notifications" />
          <Card
            css={css`
              padding: 2rem 3rem 1rem 3rem;
            `}
          >
            <FormGroup label="Email Notification Preferences" helperText="">
              <Switch
                checked={notifications.notify_order_started}
                label="Order Started"
                onChange={(e) => {
                  setNotifications((prev) => ({
                    ...prev,
                    notify_order_started: e.target.checked,
                  }));

                  updateNotification({
                    notify_order_started: e.target.checked,
                  });
                }}
              />
              <Switch
                checked={notifications.notify_order_complete}
                label="Order Complete"
                onChange={(e) => {
                  setNotifications((prev) => ({
                    ...prev,
                    notify_order_complete: e.target.checked,
                  }));

                  updateNotification({
                    notify_order_complete: e.target.checked,
                  });
                }}
              />
              <Switch
                checked={notifications.notify_survey_locked}
                label="Survey Locked"
                onChange={(e) => {
                  setNotifications((prev) => ({
                    ...prev,
                    notify_survey_locked: e.target.checked,
                  }));

                  updateNotification({
                    notify_survey_locked: e.target.checked,
                  });
                }}
              />
            </FormGroup>
          </Card>
        </Popover>
        <Popover interactionKind={PopoverInteractionKind.HOVER}>
          <NavButton icon="cog" />
          <Card>
            <RadioGroup
              label="Units"
              inline
              onChange={(event) => setUnit(event.target.value)}
              selectedValue={unit}
            >
              <Radio label="Metric" value="metric" />
              <Radio label="Imperial" value="sae" />
            </RadioGroup>
          </Card>
        </Popover>
      </ButtonGroup>
      <NavbarDivider />
      {user && <div>{user.name}</div>}
      <NavbarDivider />
      <Button minimal icon="log-out" text="Logout" onClick={logout} />
    </NavbarGroup>
  );
};
export default AuthenticatedHeader;
