class ApiAuthenticate {
  public static login(email: string, password: string): Promise<any> {
    const loginRoute = "/api/v1/auth/login";
    return fetch(loginRoute, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    })
      .then((res) => res.json())
      .catch((err) => console.error(err));
  }

  public static requestCode(email: string): Promise<any> {
    return fetch("/api/v1/auth/requestcode", {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  }

  public static resetPassword(
    email: string,
    resetCode: string,
    newPassword: string
  ): Promise<any> {
    const resetPasswordRoute = "/api/v1/auth/resetpassword";
    return fetch(resetPasswordRoute, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        resetCode,
        newPassword,
      }),
    })
      .then((res) => res.json())
      .catch((err) => console.error(err));
  }

  public static logout(): Promise<any> {
    return fetch("/api/v1/auth/logout", {
      method: "POST",
    }).then((res) => res.json());
  }

  public static whoami(): Promise<any> {
    return fetch("/api/v1/auth/whoami")
      .then((res) => res.json())
      .catch((err) => console.error(err));
  }
}

export default ApiAuthenticate;
