import * as React from "react";
import { css } from "@emotion/core";

const Spacer = ({ size = 1 }) => (
  <div
    css={css`
      height: ${size}rem;
    `}
  />
);

export default Spacer;
