import Annotation from "../models/Annotation";
import Layer from "../models/Layer";
import UndoArchiveManager from "../services/UndoArchiveManager";

import { PimentoPost, PimentoPut } from "./apiUtils";
import { getNextNumericalLabel } from "../utils";
import ApiLayers from "./layers";

import { IAnnotationFields, IAnnotationAttributeFields } from "../interfaces";

class ApiAnnotations {
  public static react: any;
  public loading: boolean;

  public static async createAnnotation(
    layerId: number | null,
    fields: IAnnotationFields,
    surveyId?: number | undefined
  ): Promise<any> {
    if (!layerId && !surveyId) {
      throw new Error(
        `New annotation must be given either a layer ID or a survey ID.`
      );
    }

    const layer = layerId
      ? Layer.find(layerId)
      : await ApiLayers.createAnnotationLayer(surveyId);

    const label =
      fields.label ||
      getNextNumericalLabel(
        "Marker",
        layer.annotations.map((a) => a.label)
      );
    const createAnnotationRoute = `/api/v1/layers/${layer.id}/annotations`;
    return PimentoPost(
      createAnnotationRoute,
      {
        ...fields,
        layer_id: layerId || fields.layer_id,
        label,
        color: layer.render_properties.color || null,
      },
      { error: `Problem creating annotation:` }
    ).then((res) => {
      if (res.data.annotation) {
        const newAnnotation = new Annotation(res.data.annotation);

        ApiAnnotations.react.setState(
          (prev) => ({ annotations: [...prev.annotations, newAnnotation] }),
          newAnnotation.inspect
        );

        return newAnnotation;
      }
    });
  }

  public static async updateAnnotation(
    annotationId: number,
    fields: IAnnotationFields
  ): Promise<any> {
    if (!Number.isInteger(annotationId)) {
      throw new Error(
        `No ID provided to updateAnnotation. Received: <<${annotationId}>>`
      );
    }
    const updateAnnotationRoute = `/api/v1/annotations/${annotationId}`;
    return PimentoPost(updateAnnotationRoute, fields, {
      error: `Server error while trying to update annotation with ID <<${annotationId}>>:`,
    })
      .then((res) => {
        if (res.data.annotation) {
          const updatedAnnotation = Annotation.find(res.data.annotation.id);
          updatedAnnotation.setAttributes(res.data.annotation);
          Annotation.poke();
          return updatedAnnotation;
        }
      })
      .catch((err) => console.error(err));
  }

  public static archive(annotationId: number) {
    if (!Number.isInteger(annotationId)) {
      throw new Error(
        `No ID provided to annotation archive. Received: <<${annotationId}>>`
      );
    }
    const archiveAnnotationRoute = `/api/v1/annotations/${annotationId}/archive`;
    return PimentoPost(archiveAnnotationRoute, null, {
      error: `Server error while trying to archive annotation with ID <<${annotationId}>>:`,
    })
      .then((res) => {
        if (res.data.annotation) {
          const archivedAnnotation = Annotation.find(res.data.annotation.id);
          archivedAnnotation.setAttributes(res.data.annotation);
          UndoArchiveManager.add("annotation", archivedAnnotation.id);
          return archivedAnnotation;
        }
      })
      .catch((err) => console.error(err));
  }

  public static unarchive(annotationId: number) {
    if (!Number.isInteger(annotationId)) {
      throw new Error(
        `No ID provided to annotation unarchive. Received: <<${annotationId}>>`
      );
    }
    const unarchiveAnnotationRoute = `/api/v1/annotations/${annotationId}/unarchive`;
    return PimentoPost(unarchiveAnnotationRoute, null, {
      error: `Server error while trying to unarchive annotation with ID <<${annotationId}>>:`,
    })
      .then((res) => {
        if (res.data.annotation) {
          const unarchivedAnnotation = Annotation.find(res.data.annotation.id);
          unarchivedAnnotation.setAttributes(res.data.annotation);
          UndoArchiveManager.remove("annotation", unarchivedAnnotation.id);
          return unarchivedAnnotation;
        }
      })
      .catch((err) => console.error(err));
  }

  public static createAttribute(
    annotationId: number,
    fields: IAnnotationAttributeFields
  ): Promise<any> {
    if (!Number.isInteger(annotationId)) {
      throw new Error(
        `No ID provided to updateAnnotation. Received: <<${annotationId}>>`
      );
    }
    const createAttributeRoute = `/api/v1/annotations/${annotationId}/attributes`;

    return PimentoPost(
      createAttributeRoute,
      { ...fields, annotation_id: annotationId },
      {
        error: `Server error while trying to create annotation with ID <<${annotationId}>>:`,
      }
    )
      .then((res) => {
        if (res.data.attribute) {
          const updatedAnnotation = Annotation.find(
            res.data.attribute.annotation_id
          );

          updatedAnnotation.updateUserAttribute(res.data.attribute);
          Annotation.poke();
          return updatedAnnotation;
        }
      })
      .catch((err) => console.error(err));
  }

  public static updateAttribute(
    attributeId: number,
    value: string
  ): Promise<any> {
    if (!Number.isInteger(attributeId)) {
      throw new Error(
        `No ID provided to updateAttribute. Received: <<${attributeId}>>`
      );
    }
    const updateAnnotationRoute = `/api/v1/attributes/${attributeId}`;
    return PimentoPost(
      updateAnnotationRoute,
      { value },
      {
        error: `Server error while trying to update attribute with ID <<${attributeId}>>:`,
      }
    )
      .then((res) => {
        if (res.data.attribute) {
          const updatedAnnotation = Annotation.find(
            res.data.attribute.annotation_id
          );
          updatedAnnotation.updateUserAttribute(res.data.attribute);
          Annotation.poke();
          return updatedAnnotation;
        }
      })
      .catch((err) => console.error(err));
  }

  public static createUserMeasurement(id: number, body: any) {
    if (!Number.isInteger(id)) {
      throw new Error(
        `No ID provided to updateAnnotation. Received: <<${id}>>`
      );
    }
    const createUserMeasurementRoute = `/api/v1/annotations/${id}/user_measurements`;

    return PimentoPost(createUserMeasurementRoute, body, {
      error: `Server error while trying to create annotation with ID <<${id}>>:`,
    }).catch((err) => console.error(err));
  }

  public static updateUserMeasurement(id: number, index: number, body: any) {
    if (!Number.isInteger(id)) {
      throw new Error(
        `No ID provided to updateAnnotation. Received: <<${id}>>`
      );
    }
    const updateUserMeasurementRoute = `/api/v1/annotations/${id}/user_measurements/${index}`;

    return PimentoPut(updateUserMeasurementRoute, body, {
      error: `Server error while trying to create annotation with ID <<${id}>>:`,
    }).catch((err) => console.error(err));
  }

  public static connect(getState, setState) {
    this.react = { getState, setState };
  }
}

export default ApiAnnotations;
