import { PimentoPost } from "./apiUtils";

export default class ApiMessaging {
  public static sendLabelsToFybr(surveyId: number, comment: string = "") {
    return PimentoPost("/api/v1/messaging/send/annotationReady", {
      surveyId,
      comment,
    });
  }
  public static supportRequest(
    user: { id: number; email: string },
    subject: string,
    details: string,
    files?: File[],
    surveyId?: string
  ) {
    const formData = new FormData();
    formData.append("subject", subject);
    formData.append("details", details);
    formData.append("userId", String(user.id));

    if (surveyId) {
      formData.append("surveyId", surveyId);
    }

    files.forEach((file) => formData.append("attachments", file));

    return fetch("/api/v1/support", {
      method: "POST",
      headers: {
        /* Leaving this out so that the form boundary is included */
        // "Content-Type": "multipart/form-data",
        // "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData,
    }).then((response) => response.json());
  }
}
