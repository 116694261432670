import * as React from "react";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { Machine, assign } from "xstate";
import { useMachine } from "@xstate/react";
import { useMapState, useSinkState } from "context/";
import ApiAnnotations from "api/annotations";
import { useAnnotationActions } from "context/";

const DrawingStateContext = React.createContext({});
const DrawingActionsContext = React.createContext({});

const VALID_MODES = ["select", "marker", "linestring", "polygon"];

const drawingMachine = Machine(
  {
    id: "drawingController",
    initial: "loading",
    context: {
      mode: null,
      activeDrawingLayer: null,
      mapRef: null,
      drawRef: null,
    },
    states: {
      loading: {
        on: {
          LOADED: "select",
        },
        exit: assign((context, event) => ({
          mapRef: event.mapRef,
          drawRef: event.drawRef,
        })),
      },
      select: {
        entry: assign({ mode: "select" }),
        invoke: {
          src: "changeMode",
        },
      },
      marker: {
        entry: assign({ mode: "marker" }),
        invoke: {
          src: "changeMode",
        },
      },
      linestring: {
        entry: assign({ mode: "linestring" }),
        invoke: [
          {
            src: "changeMode",
          },
          {
            src: "create",
          },
        ],
        on: {
          COMPLETE: "select",
        },
      },
      polygon: {
        entry: assign({ mode: "polygon" }),
        invoke: [
          {
            src: "changeMode",
          },
          {
            src: "create",
          },
        ],
        on: {
          COMPLETE: "select",
        },
      },
    },
    on: {
      SET_SURVEY_ID: {
        actions: assign({ surveyId: (context, event) => event.id }),
      },
      GOTO_SELECT: "select",
      GOTO_MARKER: "marker",
      GOTO_LINESTRING: "linestring",
      GOTO_POLYGON: "polygon",
      SET_DRAWING_LAYER: {
        actions: "setDrawingLayer",
      },
      SET_DRAWING_LAYER_IMMEDIATE: {
        actions: "setDrawingLayer",
      },
      CLEAR_DRAWING_LAYER: {
        target: "select",
        actions: "clearDrawingLayer",
      },
      CLEANUP: "loading",
    },
  },
  {
    guards: {},
    services: {
      changeMode: (context, event) => {
        context.drawRef.current.changeMode(
          (
            {
              select: "simple_select",
              marker: "simple_select",
              linestring: "draw_line_string",
              polygon: "draw_polygon",
            } as any
          )[context.mode] || "simple_select"
        );
        return Promise.resolve();
      },
    },
    actions: {
      setDrawingLayer: assign({
        activeDrawingLayer: (context, event) => event.layer || null,
      }),
      clearDrawingLayer: assign({
        activeDrawingLayer: (context, event) => null,
      }),
    },
  }
);

export const DrawingProvider = ({ children, surveyId }) => {
  const { select } = useAnnotationActions();

  const drawRef = React.useRef(
    new MapboxDraw({ displayControlsDefault: false })
  );
  const { map: mapRef, loaded } = useMapState();
  const { selectedSurveyId } = useSinkState();
  const createAnnotationRef = React.useRef(null);

  React.useEffect(() => {
    createAnnotationRef.current = (callback) => {
      /*
        using the state.context closure here so that even if the selected
        layer changes while in a drawing mode it will have the latest value
      */
      const context = state.context;
      const { features } = context.drawRef.current.getAll();

      callback("COMPLETE");

      /* TODO: add some error handling */
      ApiAnnotations.createAnnotation(
        context.activeDrawingLayer && context.activeDrawingLayer.id,
        {
          label:
            features[0].geometry.type.replace("LineString", "Line") ||
            "New Annotation",
          description: "",
          geometry: JSON.stringify(features[0].geometry),
        },
        context.surveyId
      ).then((response) => {
        context.drawRef.current.deleteAll();
        select(response.id);
        send("SET_DRAWING_LAYER_IMMEDIATE", { layer: response.layer });
      });
    };
  });

  const createAnnotation = (context, event) => (callback, onReceive) => {
    const mode = () => callback("COMPLETE");
    const create = () => createAnnotationRef.current(callback);

    context.mapRef.on("draw.create", create);
    context.mapRef.on("draw.modechange", mode);

    return () => {
      context.mapRef.off("draw.create", create);
      context.mapRef.off("draw.modechange", mode);
    };
  };

  const [state, send] = useMachine(
    drawingMachine
      .withConfig({
        services: {
          create: createAnnotation,
        },
      })
      .withContext({
        ...drawingMachine.context,
        surveyId,
      })
  );

  React.useEffect(() => {
    send("SET_SURVEY_ID", { id: surveyId });
  }, [surveyId]);

  React.useEffect(() => {
    if (mapRef && loaded) {
      const control = drawRef.current;
      mapRef.addControl(control);
      send("LOADED", { mapRef, drawRef });
      return () => {
        mapRef.removeControl(control);
      };
    }
  }, [mapRef, loaded, surveyId]);

  React.useEffect(() => {
    if (selectedSurveyId === null && state.context.drawRef) {
      send("GOTO_SELECT");
      send("CLEAR_DRAWING_LAYER");
    }
  }, [selectedSurveyId]);

  const actions = {
    setMode: (mode) => {
      if (!VALID_MODES.includes(mode)) {
        console.error(
          `⚠️ Map Toolbar cannot set draw tools to mode <<${mode}>>. ` +
            `Valid modes are: ${VALID_MODES}`
        );
      }

      /* this allows it to work with all the `setMode` calls
         we want the mode to be managed by the state transitions
         not the context, this way our transitions can stay explicit */
      send(`GOTO_${mode.toUpperCase()}`);
    },
    setActiveDrawingLayer: (layer) => {
      send("SET_DRAWING_LAYER", { layer });
    },
    setActiveDrawingLayerImmediate: (layer) => {
      send("SET_DRAWING_LAYER_IMMEDIATE", { layer });
    },
    clearActiveDrawingLayer: (layer) => {
      if (layer === state.context.activeDrawingLayer) {
        send("CLEAR_DRAWING_LAYER");
      }
    },
    toggleActiveDrawingLayer: (layer) => {
      if (layer === state.context.activeDrawingLayer) {
        send("CLEAR_DRAWING_LAYER");
      } else {
        send("SET_DRAWING_LAYER", { layer });
      }
    },
  };

  return (
    <DrawingStateContext.Provider value={state.context}>
      <DrawingActionsContext.Provider value={actions}>
        {children}
      </DrawingActionsContext.Provider>
    </DrawingStateContext.Provider>
  );
};

export const useDrawingState = () => {
  const context = React.useContext(DrawingStateContext);
  if (context === undefined) {
    throw new Error("Must 'useDrawingState' within a DrawingProvider.");
  }
  return context;
};

export const useDrawingActions = () => {
  const context = React.useContext(DrawingActionsContext);
  if (context === undefined) {
    throw new Error("Must 'useDrawingState' within a DrawingProvider.");
  }
  return context;
};
