import _ from "lodash";

import Survey from "./Survey";
import Layer from "./Layer";

import ApiAnnotations from "../api/annotations";
import { IAnnotationAttributeFields } from "interfaces";

export default class Annotation {
  public id: number;
  public class: typeof Annotation;
  public layer_id: number;
  public label: string;
  public source_id: string;
  public description: string;
  public geometry: { [key: string]: any };
  public properties: { [key: string]: any };
  public user_measurements: { [key: string]: any };
  public attributes: {
    id: number;
    label: string;
    label_id: number;
    value: string;
  }[];
  public material_id: number;
  public color: string;
  public archived: number | null;
  public isEditable: boolean;

  public react: any;
  public api: any;

  public static react: any;

  public constructor(attributes: any) {
    this.class = Annotation;
    this.setAttributes(attributes, false);
    this.sortUserAttributes();
    this.isEditable = true;
    this.configureApi();
    this.react = {
      getState: Annotation.react.getState,
      setState: Annotation.react.setState,
    };

    this.inspect = this.inspect.bind(this);

    this.select = this.select.bind(this);
    this.unselect = this.unselect.bind(this);
    this.focus = this.focus.bind(this);
    this.unfocus = this.unfocus.bind(this);
  }

  public get layer() {
    return Layer.find(this.layer_id);
  }

  public get isSelected() {
    return this.react.getState().inspectorModel === this;
  }
  public get isFocused() {
    return this.id === this.react.getState().focusedAnnotationId;
  }
  public get type() {
    return this.geometry.geometry.type;
  }
  public get visible() {
    return this.layer.visible;
  }

  public setAttributes(
    attributes: { [key: string]: any },
    poke: boolean = true
  ) {
    const allowed = [
      "id",
      "layer_id",
      "label",
      "description",
      "geometry",
      "properties",
      "user_measurements",
      "attributes",
      "color",
      "archived",
      "material_id",
      "editable",
      "source_id",
      "reference_id",
    ];

    (Object as any).entries(attributes).forEach(([k, v]) => {
      if (allowed.includes(k)) {
        this[k] = v;
      } else {
        console.warn(
          `Attempted to attach unknown attribute to Annotation model: <<${k}>>. If this is deliberate, it must be added to the data whitelist in the Annotation model.`
        );
      }

      // Triggers React state update by default, but you can
      // skip that if you're pretty sure that you don't need it.
      // poke && this.poke(); TODO: If the year is 2020 and nobody seems to have noticed that this poke was necessary, go ahead and remove it.
    });

    if (this.attributes === null) {
      this.attributes = [];
    }

    this.sortUserAttributes();
  }

  private sortUserAttributes() {
    if (this.attributes) {
      this.attributes.sort(({ label_id: aid }, { label_id: bid }) => aid - bid);
    }
  }

  public addUserAttribute(label_id: number, label: string) {
    this.attributes.push({
      id: null,
      label,
      label_id,
      value: "",
    });
  }

  public removeUserAttribute(id: number) {
    this.attributes = this.attributes.filter(
      (attribute) => attribute.label_id !== id
    );
  }

  public updateUserAttribute(attribute: any) {
    if (this.attributes === null) {
      throw new Error("Cannot change attributes, there are none");
    }

    const index = this.attributes.findIndex(
      (a) => a.label_id === attribute.label_id
    );

    if (index >= 0) {
      this.attributes = this.attributes.map((a, i) =>
        i === index ? { ...a, id: attribute.id, value: attribute.value } : a
      );
    }
  }

  public configureApi() {
    this.api = {
      archive: () => ApiAnnotations.archive(this.id),
      unarchive: () => ApiAnnotations.unarchive(this.id),
      update: (label) => ApiAnnotations.updateAnnotation(this.id, { label }),
    };
  }

  public inspect(showMaterial?: boolean) {
    this.select();
    this.react.setState({
      inspectorModel: this,
      inspectorShowMaterial: showMaterial
        ? this.react.getState().inspectorShowMaterial + 1
        : 0,
    });
  }

  /*
    Set as _the_ selected annotation in the Portal. There is only one selected annotation at a time.
  */
  public select(): void {
    this.react.setState({ selectedAnnotationId: this.id });

    // Drop the label after an interval
    setTimeout(this.unfocus, 3000);
    // this.poke() TODO: If the year is 2020 and nobody seems to have noticed that this poke was necessary, go ahead and remove it.
  }

  /*
    Deselects the current annotation. Does nothing if the current annotation is not the selected one. Should be idempotent.
  */
  public unselect(): void {
    if (this.react.getState().selectedAnnotationId === this.id) {
      this.react.setState({ selectedAnnotationId: null });
      // this.poke() TODO: If the year is 2020 and nobody seems to have noticed that this poke was necessary, go ahead and remove it.
    }
  }

  public focus(): void {
    this.react.setState({ focusedAnnotationId: this.id });
    // this.poke() TODO: If the year is 2020 and nobody seems to have noticed that this poke was necessary, go ahead and remove it.
  }

  /*
    Unfocus the current annotation. Does nothing if the current annotation is not the focused one. Should be idempotent.
  */
  public unfocus(): void {
    if (this.react.getState().focusedAnnotationId === this.id) {
      this.react.setState({ focusedAnnotationId: null });
      // this.poke() TODO: If the year is 2020 and nobody seems to have noticed that this poke was necessary, go ahead and remove it.
    }
  }

  private poke(): void {
    Annotation.poke();
  }

  public static poke(): void {
    Annotation.react.setState((prev) => ({
      annotations: [...prev.annotations],
    }));
  }

  public static get focusedAnnotationId() {
    return this.react.getState().focusedAnnotationId;
  }

  public static find(id: number) {
    return Annotation.react
      .getState()
      .annotations.find((anno) => anno.id === id);
  }

  public static forSurvey(id: number | null): Annotation[] {
    if (!id) return [];

    const survey = Survey.find(id);
    if (!survey) return [];

    return (
      _.flattenDeep(
        survey.layers.map((layer) =>
          layer.annotations.map((annotation) => ({ ...annotation, layer }))
        )
      ) || []
    );
  }

  public static unfocusAll() {
    /*
      Not strictly necessary, as only one annotation can be focused at a time, but this
      helps duck typing with Assets.
    */
    Annotation.react.setState({ focusedAnnotationId: null });
  }

  public static connect(getState, setState): void {
    this.react = { getState, setState };
  }
}
