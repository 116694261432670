import { isBefore, isAfter, isSameDay } from "date-fns";

export class Survey {
  static react: any;
  class: typeof Survey;
  react: any;
  [key: string]: any;

  public constructor(apiData, getState, setState, history) {
    const apiDataWhitelist = [
      "id",
      "organization_id",
      "label",
      "nominal_flight_date",
      "folder_id",
      "site_id",
      "zoom_start",
      "coordinates",
      "group_ids",
      "timezone",
      "status",
      "location",
      "level",
      "locked_time",
      "locked_by_id",
      "locked_by_name",
      "start_time",
      "end_time",
    ];

    (Object as any).entries(apiData).forEach(([k, v]) => {
      if (apiDataWhitelist.includes(k)) {
        this[k] = v;
      } else {
        console.warn(
          `Attempted to attach unknown attribute to survey model: <<${k}>>. If this is deliberate, it must be added to the data whitelist in the Survey model.`
        );
      }
    });

    this.react = {
      getState,
      setState,
    };

    this.history = history;

    this.class = Survey;
    this.select = this.select.bind(this);
    this.unselect = this.unselect.bind(this);
    this.focus = this.focus.bind(this);
    this.unfocus = this.unfocus.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.defaultAnnotationLayerId = null;
  }

  public get layers() {
    return this.react.getState().layers.filter((l) => l.survey_id === this.id);
  }
  public get annotationLayers() {
    return this.react
      .getState()
      .layers.filter((l) => l.survey_id === this.id && l.type === "annotation");
  }

  public get isASurvey() {
    return true;
  }
  public get isSelected() {
    return this.react.getState().selectedSurveyId === this.id;
  }
  public get isFocused() {
    return this.id === this.react.getState().focusedSurveyId;
  }
  public get isInFilterSelection() {
    return (
      !!Survey.byDateRange([this])[0] && !!Survey.bySearchString([this])[0]
    );
  }
  /*
    Set as _the_ selected survey in the Portal. There is only one selected survey at a time.
  */
  public select(): void {
    this.open();
    this.react.setState((prev) => ({
      ...prev,
      mapState: { ...prev.mapState, selectedStyle: "Satellite" },
    }));
    this.history.push(`/surveys/${this.id}`, null);

    // Drop the label after an interval
    setTimeout(this.unfocus, 3000);
  }

  /*
    Deselects the current survey. Does nothing if the current survey is not the selected one. Should be idempotent.
  */
  public unselect(): void {
    if (this.react.getState().selectedSurveyId === this.id) {
      this.react.setState({ selectedSurveyId: null });
      // this.poke() TODO: If the year is 2020 and nobody seems to have noticed that this poke was necessary, go ahead and remove it.;
    }
  }

  public focus(): void {
    this.react.setState({ focusedSurveyId: this.id });
    // this.poke() TODO: If the year is 2020 and nobody seems to have noticed that this poke was necessary, go ahead and remove it.;
  }

  /*
    Unfocus the current survey. Does nothing if the current survey is not the focused one. Should be idempotent.
  */
  public unfocus(): void {
    if (this.react.getState().focusedSurveyId === this.id) {
      this.react.setState({ focusedSurveyId: null });
      // this.poke() TODO: If the year is 2020 and nobody seems to have noticed that this poke was necessary, go ahead and remove it.;
    }
  }

  /*
    The Portal maintains a list of which surveys are "open." This adds the current survey to that list if it is not
    already included. Should be idempotent.
  */
  public open(): void {
    if (this.react.getState().openSurveyIds.includes(this.id)) return;

    // Provisionary behavior: Opening a survey replaces any existing open surveys
    this.react.setState((prev) => ({ openSurveyIds: [this.id] }));

    // Sidelined behavior: Multiple surveys can be open at a time
    // this.react.setState(prev => ({ openSurveyIds: [...prev.openSurveyIds, this.id] }));
  }

  /*
    The Portal maintains a list of which surveys are "open." This removes the current survey from that list, if it
    is in it. It also unselects the current survey if it is selected.

    TODO: There is a side effect where, if this survey is to be unselected, a new survey may be selected. This is a holdover
    from when this method was part of the Portal component, and that logic should find a new home ASAP.
  */
  public close() {
    // Remove this survey from the list of open surveys
    this.react.setState((prev) => ({
      openSurveyIds: prev.openSurveyIds.filter((id) => id !== this.id),
    }));

    /* TODO: This "select the next survey" logic was basically copy-pasta'd from a Portal method...
    Because "next survey" is a consequence of which surveys have been opened by the user, this logic is too tightly
    entangled with the UI state to be a good model method. It should find a new home ASAP.
     */
    if (this.isSelected) {
      this.unselect();
      // "Grab the survey before the current selectedSurvey, and if there are none before, set to null."
      const previousSurveyId =
        this.react.getState().openSurveyIds[
          this.react.getState().openSurveyIds.indexOf(this.id) - 1
        ] || null;
      const previousSurvey =
        previousSurveyId &&
        this.react.getState().surveys.find((s) => s.id === previousSurveyId);
      previousSurvey
        ? previousSurvey.select()
        : this.react.setState({ selectedSurveyId: null });
    }
  }

  // public setDefaultAnnotationLayer(layerId: number) {
  //   const targetLayer = this.annotationLayers.find((l) => l.id === layerId);
  //   if (!targetLayer) {
  //     return console.warn(
  //       `⚠️ Tried to set default annotation layer for survey ${this.id} to layer with ID ${layerId}, but layer was not found`
  //     );
  //   }
  //   this.defaultAnnotationLayerId = layerId;
  //   // this.poke() TODO: If the year is 2020 and nobody seems to have noticed that this poke was necessary, go ahead and remove it.;
  //   // targetLayer.poke();
  // }

  // public resetDefaultAnnotationLayer() {
  //   this.defaultAnnotationLayerId = null;
  // }

  // Perturbs the collection to trigger a re-render. If this becomes a performance bottleneck, we'll need to
  // find a way to perturb a single item instead of an entire collection.
  private static get all(): Survey[] {
    return this.react.getState().surveys;
  }

  private poke() {
    this.react.setState((prev) => ({ surveys: [...prev.surveys] }));
  }

  public static connect(getState, setState) {
    this.react = { getState, setState };
  }

  public static find(id: number): Survey | undefined {
    return Survey.react.getState().surveys.find((s) => s.id === id);
  }

  public static byAllFilters(_surveys?: Survey[], options) {
    const surveys = _surveys || this.all;
    const filters = [
      this.bySearchString.bind(this),
      this.byDateRange.bind(this),
      this.byMapViewport.bind(this),
    ];

    return filters.reduce((result, filter) => filter(result, options), surveys);
  }

  public static byDateRange(_surveys?: Survey[]): Survey[] {
    const state = this.react.getState();
    const surveys = _surveys || this.all;
    const startDate = state.surveyDateRange.startDate;
    const endDate = state.surveyDateRange.endDate;

    if (!startDate || !endDate) return surveys;

    return surveys.filter((survey) => {
      if (
        isSameDay(new Date(survey.nominal_flight_date), startDate) ||
        isSameDay(new Date(survey.nominal_flight_date), endDate)
      ) {
        return true;
      }

      if (
        isAfter(new Date(survey.nominal_flight_date), startDate) &&
        isBefore(new Date(survey.nominal_flight_date), endDate)
      ) {
        return true;
      }

      return false;
    });
  }

  public static bySearchString(_surveys?: Survey[]): Survey[] {
    // Searches all search terms separately across both label and nominal_flight_date; Search must match _all_ terms.
    // This code certainly has a refactor in its future, like having list of searchable fields that are iterated
    // through dynamically, but I'm quite happy with this for now.
    const surveys = _surveys || this.all;
    const { surveySearchString } = this.react.getState();
    if (!surveySearchString) return surveys;
    return (
      surveys &&
      surveys.filter(
        (survey) =>
          !surveySearchString ||
          surveySearchString.split(" ").reduce((match, searchTerm) => {
            if (!match) return false;
            const { label, nominal_flight_date, site_name } = survey;
            const term = searchTerm.toLowerCase();
            return (
              (site_name && site_name.toLowerCase().includes(term)) ||
              (label && label.toLowerCase().includes(term))
            );
          }, true)
      )
    );
  }

  public static byMapViewport(_surveys?: Survey[], options): Survey[] {
    if (!options.viewport) {
      return _surveys;
    }

    const surveys = _surveys || this.all;

    return surveys.filter((survey) =>
      options.viewport.contains([
        survey.coordinates.longitude,
        survey.coordinates.latitude,
      ])
    );
  }
}

export default Survey;
