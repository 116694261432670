import * as React from "react";
import { withRouter } from "react-router-dom";

import { Loading } from "../components/common";

import Survey from "../models/Survey";
import Layer from "../models/Layer";
import Asset from "../models/Asset";
import Annotation from "../models/Annotation";
import AdHoc from "../models/AdHoc";
import Cannot from "../models/Cannot";

import ApiSink from "../api/sink";
import ApiLayers from "../api/layers";
import ApiAnnotations from "../api/annotations";

import UndoArchiveManager from "../services/UndoArchiveManager";
import { useAuthActions } from "./auth";
import useRefreshOnFocus from "../hooks/useRefreshOnFocus";

const SinkStateContext = React.createContext();
const SinkActionsContext = React.createContext();

interface IPortalState {
  loading: boolean;
  sink: any;
  surveys: Survey[];
  layers: Layer[];
  assets: Asset[];
  annotations: Annotation[];
  selectedSurveyId: number | null;
  focusedSurveyId: number | null;
  selectedModelId: number | null;
  inspectorModel: any;
  inspectorShowMaterial: number;
  openSurveyIds: number[];
  selectedAssetId: number | null;
  focusedAssetIds: number[];
  isCenterPaneCollapsed: boolean;
  surveySearchString: string | null;
  surveyDateRange: { startDate: string; endDate: string };
  mapState: { [key: string]: any };
  undoArchiveQueue: { type: string; id: number }[];
}

const apiSink = new ApiSink();

const defaultState: IPortalState = {
  loading: true,
  sink: {},
  surveys: [],
  layers: [],
  annotations: [],
  assets: [],
  selectedSurveyId: null,
  focusedSurveyId: null,
  selectedAnnotationId: null,
  focusedAnnotationId: null,
  selectedModelId: null,
  inspectorModel: null,
  inspectorShowMaterial: 0,
  openSurveyIds: [],
  selectedAssetId: null,
  focusedAssetIds: [],
  isCenterPaneCollapsed: true,
  surveySearchString: null,
  surveyDateRange: { startDate: "", endDate: "" },
  undoArchiveQueue: [],
};

const resetState = {
  focusedSurveyId: null,
  selectedModelId: null,
  inspectorShowMaterial: 0,
  openSurveyIds: [],
  selectedAssetId: null,
  focusedAssetIds: [],
  isCenterPaneCollapsed: true,
  surveySearchString: null,
  surveyDateRange: { startDate: "", endDate: "" },
};

const SinkStore = withRouter(({ setState, getState, children, history }) => {
  const state = getState();

  const { logout } = useAuthActions();

  const actions = {
    resetUiState: () => {
      setState(resetState);
      Layer.resetAllVisibilities();
    },
    unsetSelectedSurvey: () => {
      UndoArchiveManager.clearQueue();
      setState((prev) => ({
        ...prev,
        selectedSurveyId: null,
        openSurveyIds: [],
        selectedModelId: null,
        selectedAssetId: null,
        inspectorModel: null,
      }));
    },
    setSelectedModel: (id: number | null) => {
      const expandCenterPane = !id;
      actions.setCenterPaneCollapsed(expandCenterPane); // Expand the center pane if a model was clicked (and collapse if the model was reset)
      setState({ selectedModelId: id && Number(id) });
    },
    setSelectedAsset: (assetId: number) => {
      setState({ selectedAssetId: assetId });
    },
    setCenterPaneCollapsed: (isCollapsed: boolean): void => {
      setState({ isCenterPaneCollapsed: isCollapsed });
    },
    setSurveySearchString: (surveySearchString: string): void => {
      setState({ surveySearchString });
    },
    setSurveyDateRange: (d: { startDate?: string; endDate?: string }): void => {
      setState({ surveyDateRange: d });
    },
    stopInspecting: () => {
      if (state.inspectorModel && state.inspectorModel.unselect) {
        state.inspectorModel.unselect();
      }
      setState({ inspectorModel: null, inspectorShowMaterial: 0 });
    },
    setSelectedSurveyId: (id) => {
      setState({
        selectedSurveyId: id,
      });
    },
    resink: () => {
      return apiSink.getSink().then(({ data: { sink } }) => {
        setState({
          sink,
          surveys: sink.surveys.map(
            (survey) => new Survey(survey, getState, setState, history)
          ),
        });
      });
    },
    getLayers: (surveyId) => {
      return apiSink.getAnnotatedLayers(surveyId).then((response) => {
        const { inspectorModel } = getState();
        const annotations = response.data.annotations.map(
          (annotation) => new Annotation(annotation)
        );

        const match =
          inspectorModel &&
          annotations.find((annotation) => annotation.id === inspectorModel.id);

        setState((prev) => ({
          ...prev,
          layers: response.data.layers.map(
            (layer) => new Layer(layer, getState, setState)
          ),
          annotations,
          inspectorModel: match || inspectorModel,
        }));
      });
    },
  };

  const refreshSink = React.useCallback(() => {
    Survey.connect(getState, setState);
    Layer.connect(getState, setState);
    Asset.connect(getState, setState);
    Annotation.connect(getState, setState);
    AdHoc.connect(getState, setState);
    Cannot.connect(getState, setState);

    ApiLayers.connect(getState, setState);
    ApiAnnotations.connect(getState, setState);

    UndoArchiveManager.connect(getState, setState);

    apiSink
      .getSink()
      .then((response) => {
        const { sink } = response.data;

        setState({
          sink,
          surveys: sink.surveys.map(
            (survey) => new Survey(survey, getState, setState, history)
          ),
          loading: false,
        });
      })
      .catch((error) => {
        if (error.status === 401) {
          logout();
        }
      });
  }, []);

  useRefreshOnFocus(refreshSink);

  return (
    <SinkStateContext.Provider value={state}>
      <SinkActionsContext.Provider value={actions}>
        {state.loading ? <Loading /> : children}
      </SinkActionsContext.Provider>
    </SinkStateContext.Provider>
  );
});

export class SinkProvider extends React.Component {
  state = { ...defaultState };
  getState = () => this.state;
  render() {
    return (
      <SinkStore getState={this.getState} setState={this.setState.bind(this)}>
        {this.props.children}
      </SinkStore>
    );
  }
}

export const useSinkState = () => {
  const context = React.useContext(SinkStateContext);
  if (context === undefined) {
    throw new Error("Must 'useSinkState' within a SinkProvider.");
  }
  return context;
};

export const useSinkActions = () => {
  const context = React.useContext(SinkActionsContext);
  if (context === undefined) {
    throw new Error("Must 'useSinkActions' within a SinkProvider.");
  }
  return context;
};
