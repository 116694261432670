import ApiLayers from '../api/layers';
import ApiAnnotations from '../api/annotations';

const VALID_TYPES = ['layer', 'annotation'];

export default class UndoArchiveManager {
  private static react: any;

  static get queue() { return UndoArchiveManager.react.getState().undoArchiveQueue; }
  static set queue(value) {
    UndoArchiveManager.react.setState({ undoArchiveQueue: value});
  }

  public static add(type, id) {
    if (!VALID_TYPES.includes(type)) throw new Error(`Tried to add item to undo queue with type <<${type}>>. Valid types are: ${VALID_TYPES}`);
    UndoArchiveManager.queue = [...UndoArchiveManager.queue, { type, id }];
  }

  public static remove(type, id) {
    if (!VALID_TYPES.includes(type)) throw new Error(`Tried to remove item from undo queue with type <<${type}>>. Valid types are: ${VALID_TYPES}`);
    UndoArchiveManager.queue = UndoArchiveManager.queue.filter(entry => !(entry.type === type && entry.id === id));
  }

  public static clearQueue() {
    UndoArchiveManager.queue = [];
  }

  public static async undoLast() {
    const newQueue = [...UndoArchiveManager.queue];
    const entry = newQueue.pop();
    if (entry.type === 'layer') {
      await ApiLayers.unarchiveAnnotationLayer(entry.id);
    } else if (entry.type === 'annotation') {
      await ApiAnnotations.unarchive(entry.id);
    }

    UndoArchiveManager.queue = newQueue;
  }

  public static async undoAll() {
    await Promise.all(UndoArchiveManager.queue.map(async entry => {
      if (entry.type === 'layer') {
        await ApiLayers.unarchiveAnnotationLayer(entry.id);
      } else if (entry.type === 'annotation') {
        await ApiAnnotations.unarchive(entry.id);
      }
    }));

    UndoArchiveManager.clearQueue();
  }

  public static connect(getState, setState) {
    UndoArchiveManager.react = { getState, setState };
  }
}
