import * as React from "react";
import {
  Button,
  Classes,
  H3,
  Intent,
  Overlay,
  TextArea,
  FormGroup,
  InputGroup,
} from "@blueprintjs/core";
import { useParams } from "react-router-dom";
import { FormAttachment } from "components/common";
import { useSupportState, useSupportActions } from "context/support";
import { useAuthState } from "context/auth";
import ApiMessaging from "../../api/messaging";

function Support() {
  const params = useParams<{ survey_id?: string }>();
  const { currentUser } = useAuthState();
  const { isOpen } = useSupportState();
  const { close } = useSupportActions();
  const [subject, setSubject] = React.useState("");
  const [details, setDetails] = React.useState("");
  const [files, setFiles] = React.useState([]);

  const reset = () => {
    setSubject("");
    setDetails("");
    setFiles([]);
  };

  const handleSubmit = () => {
    ApiMessaging.supportRequest(
      currentUser,
      subject,
      details,
      files,
      params.survey_id
    ).then(() => {
      reset();
      close();
    });
  };

  const handleClose = () => {
    reset();
    close();
  };

  const isSurvey = params.survey_id !== undefined;

  return (
    <Overlay
      onClose={handleClose}
      className={Classes.OVERLAY_SCROLL_CONTAINER}
      isOpen={isOpen}
      hasBackdrop
      usePortal
      canOutsideClickClose
      canEscapeKeyClose
      autoFocus
    >
      <div
        className={`bp3-dark ${Classes.CARD} ${Classes.ELEVATION_4} support-overlay`}
      >
        <H3>We're Here to Help</H3>
        <p>This form will allow you to create a customer support ticket.</p>
        <br />
        <form>
          {isSurvey && (
            <FormGroup label="Survey" labelFor="survey-input">
              <InputGroup id="survey-input" value={params.survey_id} disabled />
            </FormGroup>
          )}
          <FormGroup
            label="Subject"
            labelFor="subject-input"
            labelInfo="(required)"
          >
            <InputGroup
              id="subject-input"
              value={subject}
              onChange={(event) => setSubject(event.target.value)}
            />
          </FormGroup>
          <FormGroup
            label="Details"
            labelFor="details-input"
            labelInfo="(required)"
          >
            <TextArea
              id="details-input"
              growVertically
              large
              fill
              value={details}
              onChange={(event) => setDetails(event.target.value)}
            />
          </FormGroup>
          <FormAttachment files={files} setFiles={setFiles} />
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button intent={Intent.DANGER} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              intent={Intent.SUCCESS}
              onClick={handleSubmit}
              disabled={!subject || !details}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </Overlay>
  );
}

export default Support;
