export const TSON = {
  parse: function (input: string): { [key: string]: any } {
    return input.split("&&").reduce((previous, item) => {
      const kv = item.split("::");
      previous[kv[0]] = kv[1];
      return previous;
    }, {});
  },
};

/*
  Takes a `template` string and a `collection` of strings, some of which may
  match that template. For all of the strings in the collection, it asks:
  "Of the strings in the collection that match the pattern of `template` + a
  number, what is the highest number?" Then, it returns the template string
  with that number incremented by 1.

  Examples:

  Given: ('Season', ['Season 6', 'Season 7'])
  Returns: 'Season 8'

  Given: ('Red', ['Red 1', 'Blue 1', 'Red 2', 'Blue 2', 'Blue 3', 'Blue 4'])
  Returns: 'Red 3'

  Given: ('Marker', ['Sawdust', 'Chips', 'Marker 3', 'Hog', 'More Sawdust'])
  Returns: 'Marker 4'
*/
export function getNextNumericalLabel(template: string, collection: string[]) {
  const highestDefaultLabel = collection.reduce((max, next) => {
    const regex = new RegExp(`${template} (\\d*)`);
    const defaultLabel = next.match(regex);
    if (defaultLabel && defaultLabel[1]) {
      return Math.max(max, Number(defaultLabel[1]));
    }
    return max;
  }, 0);
  return `${template} ${highestDefaultLabel + 1}`;
}

export const getDecimalFromDMS = (dms: { value: number[][] }, ref: string) => {
  const degrees = dms.value[0][0] / dms.value[0][1];
  const minutes = dms.value[1][0] / dms.value[1][1] / 60;
  const seconds = dms.value[2][0] / dms.value[2][1] / 3600;

  if (["S", "W"].includes(ref)) {
    return parseFloat((-1 * degrees + -1 * minutes + -1 * seconds).toFixed(10));
  }

  return parseFloat((degrees + minutes + seconds).toFixed(10));
};

export const getCoords = (asset: {
  exif: {
    GPSLongitude: { value: number[][] };
    GPSLongitudeRef: any;
    GPSLatitude: { value: number[][] };
    GPSLatitudeRef: any;
  };
}): mapboxgl.LngLatLike => {
  if (
    asset.exif["GPSLongitude"] &&
    asset.exif["GPSLongitudeRef"] &&
    asset.exif["GPSLatitude"] &&
    asset.exif["GPSLatitudeRef"]
  ) {
    return [
      getDecimalFromDMS(
        asset.exif["GPSLongitude"],
        asset.exif["GPSLongitudeRef"].value[0]
      ),
      getDecimalFromDMS(
        asset.exif["GPSLatitude"],
        asset.exif["GPSLatitudeRef"].value[0]
      ),
    ];
  }

  /* if there is no exif, default to 0,0 and let the user decide to remove it */
  return [0, 0];
};

import {
  min as minimumDate,
  max as maximumDate,
  formatDistance,
} from "date-fns";

export function getDateList(files) {
  return files.map((file) => {
    if (file.exif.DateTimeOriginal) {
      const [date, time] = file.exif.DateTimeOriginal.value[0].split(" ");
      return new Date(`${date.replaceAll(":", "-")}T${time}`);
    }

    /* in the case that there is no exif for this image, we need some date.
       now is as good a time as any, the image should be deleted by the user
       without any lat/long as well */
    return new Date();
  });
}

export function getStartDate(files) {
  return minimumDate(getDateList(files));
}

export function getEndDate(files) {
  return maximumDate(getDateList(files));
}

export function getFlightTime(files) {
  return formatDistance(getStartDate(files), getEndDate(files));
}

type ReactEvent =
  | React.MouseEvent<HTMLElement, MouseEvent>
  | React.MouseEvent<HTMLButtonElement, MouseEvent>
  | React.ChangeEvent<HTMLInputElement>
  | React.FormEvent<HTMLFormElement>;

interface FN {
  (event: ReactEvent): void;
}

export const withPreventDefault = (fn?: FN) => (event: ReactEvent) => {
  event.preventDefault();
  if (fn) {
    fn(event);
  }
};

export const withStopPropagation = (fn?: FN) => (event: ReactEvent) => {
  event.stopPropagation();
  if (fn) {
    fn(event);
  }
};

export * from "./convert";
export * from "./format";
export * from "./mapping";
