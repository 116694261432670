import * as React from "react";
import { css } from "@emotion/core";
import { Tag } from "@blueprintjs/core";

function OrderStatus({ status }) {
  return (
    <Tag
      css={css`
        padding: 0 0.5rem !important;
        width: 120px;
        text-align: center;
      `}
      intent={
        status === "complete"
          ? "success"
          : status === "no orders"
          ? "none"
          : "warning"
      }
      round
    >
      {status.toUpperCase()}
    </Tag>
  );
}

export default OrderStatus;
