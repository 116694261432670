import polylabel from "polylabel";

const configureFillPaintProperties = (
  color: string = "rgba(25, 25, 25, 1)",
  opacity: number = 0.3
) => {
  const fillColor = color;
  const selectedFillColor = "rgba(25, 25, 25, 1)";

  return {
    "fill-color": [
      "case",
      ["boolean", ["feature-state", "hover"], false],
      fillColor,
      [
        "case",
        ["boolean", ["feature-state", "selected"], false],
        selectedFillColor,
        fillColor,
      ],
    ],
    "fill-opacity": [
      "case",
      ["boolean", ["feature-state", "hover"], false],
      0.8,
      opacity,
    ],
  };
};

const configureLinePaintProperties = (
  lineColor: string | null = "rgba(0, 240, 240, 1)",
  lineWidth: number | null = 1
) => {
  const selectedLineWidth = lineWidth + 1;

  return {
    "line-color": [
      "case",
      ["boolean", ["feature-state", "selected"], false],
      "rgba(230, 230, 230, 1)",
      [
        "case",
        ["boolean", ["feature-state", "hover"], false],
        "rgba(25, 25, 25, 1)",
        lineColor,
      ],
    ],
    "line-width": [
      "case",
      ["boolean", ["feature-state", "selected"], false],
      selectedLineWidth,
      [
        "case",
        ["boolean", ["feature-state", "hover"], false],
        selectedLineWidth,
        lineWidth,
      ],
    ],
  };
};

export const getSourceFrom = (map) => (id) => map.getSource(`fs-${id}`);

export const addSourceTo = (map) => (id, options) => {
  const { geo, label } = options;

  map.addSource(`fs-${id}`, {
    type: "geojson",
    data: {
      type: "Feature",
      geometry: geo,
      properties: {},
      id,
    },
  });

  const coordinates =
    geo.type === "LineString"
      ? [[...geo.coordinates, geo.coordinates[0]]]
      : geo.coordinates;

  const { 0: long, 1: lat } =
    geo.type === "Point" ? { ...geo.coordinates } : polylabel(coordinates, 1.0);

  map.addSource(`fs-${id}-center`, {
    type: "geojson",
    data: {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [long, lat],
      },
      properties: {
        label,
      },
      id,
    },
  });

  return map.getSource(`fs-${id}`);
};

export const addStrokeTo = (map) => (sourceId, options) => {
  const { paint } = options;

  map.addLayer({
    id: `${sourceId}-line`,
    type: "line",
    source: sourceId,
    layout: {},
    paint: configureLinePaintProperties(paint.color, paint.line_width),
  });

  map.moveLayer(`${sourceId}-line`);

  return map.getLayer(`${sourceId}-line`);
};

export const addFillTo = (map) => (sourceId, options) => {
  const { paint } = options;

  map.addLayer({
    id: `${sourceId}-fill`,
    type: "fill",
    source: sourceId,
    layout: {},
    paint: configureFillPaintProperties(paint.color, paint.opacity),
  });

  map.moveLayer(`${sourceId}-fill`);

  return map.getLayer(`${sourceId}-fill`);
};

export const addLabelTo = (map) => (sourceId) => {
  map.addLayer({
    id: `${sourceId}-label`,
    source: `${sourceId}-center`,
    type: "symbol",
    layout: {
      "text-field": ["get", "label"],
      "text-font": ["Roboto Bold", "Open Sans Bold"],
      "text-anchor": "center",
      "text-ignore-placement": true,
      "text-letter-spacing": 0.15,
      visibility: "none",
      "text-size": 20,
    },
    paint: {
      "text-color": "#e6e6e6",
      "text-halo-width": 2,
      "text-halo-color": "rgba(25, 25, 25, 1)",
      "text-halo-blur": 0,
    },
  });

  return map.getLayer(`${sourceId}-label`);
};

export const addCircleTo = (map) => (sourceId, options) => {
  map.addLayer({
    id: `${sourceId}-circle`,
    source: `${sourceId}-center`,
    type: "circle",
    paint: {
      "circle-radius": options.paint.circle_radius || 3,
      "circle-color": options.paint.color || "#00E5EE",
      "circle-opacity": options.paint.opacity || 1,
      "circle-stroke-color": options.paint.stroke_color || "#000",
      "circle-stroke-width": options.paint.stroke_width || 0,
    },
  });

  return map.getLayer(`${sourceId}-circle`);
};

export const moveLabelsOn = (map) => {
  const getSource = getSourceFrom(map);

  return (ids: number[]) => {
    if (!map) return;
    for (const id of ids) {
      const source = getSource(id);
      if (source) {
        const layer = map.getLayer(`${source.id}-label`);
        if (layer) {
          map.moveLayer(`${source.id}-label`);
        }
      }
    }
  };
};

export const moveOutlineOn = (map) => {
  const getSource = getSourceFrom(map);

  return (ids: number[]) => {
    if (!map) return;
    for (const id of ids) {
      const source = getSource(id);
      if (source) {
        const layer = map.getLayer(`${source.id}-line`);
        if (layer) {
          map.moveLayer(`${source.id}-line`);
        }
      }
    }
  };
};
