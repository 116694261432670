import * as React from "react";

const usePreventSleep = () => {
  const videoRef = React.useRef(document.createElement("video"));

  const [canSleep, setCanSleep] = React.useState(true);

  React.useEffect(() => {
    videoRef.current.muted = true;

    videoRef.current.style.position = "absolute";
    videoRef.current.style.top = "0";
    videoRef.current.style.left = "0";
    videoRef.current.style.width = "0";
    videoRef.current.style.height = "0";

    const sources = {
      webm: document.createElement("source"),
      mp4: document.createElement("source"),
    };

    sources.webm.setAttribute("src", "/nosleep/blank.webm");
    sources.webm.setAttribute("type", "video/webm");

    videoRef.current.appendChild(sources.webm);

    sources.mp4.setAttribute("src", "/nosleep/blank.mp4");
    sources.mp4.setAttribute("type", "video/mp4");
    sources.mp4.setAttribute("playsinline", "");

    videoRef.current.appendChild(sources.mp4);

    document.body.appendChild(videoRef.current);

    return () => videoRef.current.remove();
  }, []);

  const preventSleep = React.useCallback(() => {
    videoRef.current.setAttribute("loop", "loop");
    videoRef.current.play();

    setCanSleep(false);
  }, []);

  const allowSleep = React.useCallback(() => {
    videoRef.current.removeAttribute("loop");
    videoRef.current.pause();

    setCanSleep(true);
  }, []);

  return {
    preventSleep,
    allowSleep,
    canSleep,
  };
};

export default usePreventSleep;
