/*
  A model used for arbitrary bundles of key-value data that we want to treat like a model,
  in the Portal (such as for display in the Inspector), but don't actually want to track
  in state and work with as a full-fledged model. A duck suit for POJSOs.

  For example: Mapbox features, like individual trees, can be instantiated as AdHoc models
  for the purpose of inspecting, but we don't want to track them in React state-- They appear
  in React state only ephemerally, and have no associations.
*/

export default class AdHoc {
  public renderableProperties: object;
  public label: string;
  public class: typeof AdHoc;
  public static react;

  constructor(renderableProperties: object, label?: string) {
    this.renderableProperties = renderableProperties;
    this.label = label || 'FYBR Feature';
    this.class = AdHoc;
  }

  public inspect() {
    // TODO: this.select(); Should work with this.setFeatureState
    AdHoc.react.setState({ inspectorModel: this });
  }

  public static connect(getState, setState): void {
    this.react = { getState, setState };
  }
}
