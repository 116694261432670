import Layer from "./Layer";
import { TSON } from "../utils";
import * as _ from "lodash";

interface IVolumeSet {
  label: string;
  volume: string;
  mode: string;
}

// TODO: This is a dumb spot for this.
const geometryUtils = {
  getTopRight: (geometry) => {
    const flatCoords = _.flatten(geometry.coordinates);
    return flatCoords.reduce((acc, next) => {
      const [maxLong, maxLat] = acc;
      const [nextLong, nextLat] = next;
      return [Math.max(maxLong, nextLong), Math.max(maxLat, nextLat)];
    }, flatCoords[0]);
  },
};

/*
  TODO: We aspire to have, in the future, assets that have an ID more reliable than the `properties.assetId`. Right now, that's the game though.
*/
export default class Asset {
  public id: string;
  class: typeof Asset;
  public layer: Layer;
  public features: any[];
  public properties: { [key: string]: any };
  public geometryUtils: any; // TODO: This is dumb
  private react: any;
  private mapbox: any;

  public static registrationQueue: Asset[] = [];
  private static react: any;
  private static mapbox: any;

  constructor(feature: any, layer: Layer) {
    this.class = Asset;
    // Assumption: If an asset has multiple features, they will have identical properties.
    const assetObj = this.legacyMapboxAdapter(feature);
    this.layer = layer;
    this.features = [feature]; // The objects that represents how Mapbox thinks of this asset
    this.properties = assetObj;
    this.geometryUtils = geometryUtils; // TODO: This is dumb

    /*
      Should be thought of as the Pimento-POV ID.
      In the future, these should not be assetIds, because those are unreliable and not necessarily unique.
    */
    this.id = this.properties.assetId;

    this.react = {
      getState: Asset.react.getState,
      setState: Asset.react.setState,
    };
    this.mapbox = { map: Asset.mapbox };

    this.inspect = this.inspect.bind(this);

    this.select = this.select.bind(this);
    this.unselect = this.unselect.bind(this);
    this.focus = this.focus.bind(this);
    this.unfocus = this.unfocus.bind(this);

    this.queueForReactRegistration();
  }

  public get label() {
    return this.properties.label;
  } // Just an alias so that it can be used polymorphically alongside other models

  public get isSelected() {
    return this.react.getState().inspectorModel === this;
  }

  public get isFocused() {
    return !!this.react.getState().focusedAssetIds.find((a) => a === this.id);
  }

  public inspect() {
    this.select();
    this.react.setState({ inspectorModel: this });
  }

  public select() {
    this.react.setState({ selectedAssetId: this.id });
  }

  public unselect() {
    if (this.isSelected) {
      this.react.setState({ selectedAssetId: null });
    }
  }

  public focus() {
    this.react.setState((prev) => ({
      focusedAssetIds: Array.from(new Set([...prev.focusedAssetIds, this.id])),
    }));
  }

  public unfocus() {
    if (this.isFocused) {
      this.react.setState((prev) => ({
        focusedAssetIds: prev.focusedAssetIds.filter((a) => a.id !== this.id),
      }));
    }
  }

  public addFeature(feature) {
    // i.e. Check whether or not this feature is already known to this asset. Don't add duplicates.
    const existsInAsset = this.features.reduce(
      (equal, next) => equal || _.isEqual(next, feature),
      false
    );
    if (!existsInAsset) {
      this.features.push(feature);
    }
    this.refreshMapboxFeatures();
    // this.poke() TODO: If the year is 2020 and nobody seems to have noticed that this poke was necessary, go ahead and remove it.;
  }

  public setFeatureStates(args) {
    this.refreshMapboxFeatures();
    this.features.forEach((f) => {
      this.mapbox.map.setFeatureState(f, args);
    });
  }

  public refreshMapboxFeatures() {
    /*
      First, establish that the Layer that this Asset is dependent on has an up-to-date list
      of valid layer IDs.

      Then, remove any features that belong to a layer whose ID is not in that list.
    */
    this.layer.refreshMapboxLayers();
    this.features = this.features.filter((f) =>
      this.layer.mapbox.ids.includes(f.layer.id)
    );
  }

  private queueForReactRegistration() {
    /*
      Significant performance gains were noticed by batching registration
      in this way, instead of calling `setState` once per asset. Apparently
      queuing 66 setStates was enough to cause UI-locking problems. As a result,
      it's the calling code's job (i.e. the thing that calls `new Asset()`) to
      also call `Asset.resolveReactRegistrationQueue()`, or else the assets will
      never show up there.
    */
    Asset.registrationQueue = [...Asset.registrationQueue, this];
  }

  private legacyMapboxAdapter(featureObj) {
    const transformMap = {
      AssetClass: "class",
      AssetID: "assetId",
      AssetLabel: "label",
      AssetMater: "material",
      AssetNum: "number",
      VolValue1: "volumeValue1",
      VolValue2: "volumeValue2",
      VolValue3: "volumeValue3",
      changed: "changed",
    };
    if (!featureObj.properties.AssetID) {
      console.warn(
        `Incoming asset feature has no AssetID. We depend on features to have an AssetID, and other stuff will likely break if you don't have one. Feature object: ${JSON.stringify(
          featureObj
        )}`
      );
    }
    const transformedProperties: any = {};
    Object.entries(transformMap).forEach(([oldLabel, newLabel]) => {
      transformedProperties[newLabel] = featureObj.properties[oldLabel];
    });

    const volSetNames: string[] = Object.keys(
      featureObj.properties
    ).filter((k) => k.includes("VolSet"));
    transformedProperties.volumeSets = volSetNames.map((set) =>
      transformTsonVolumeSet(featureObj.properties[set])
    );

    const renegadeProperties = Object.keys(featureObj.properties).filter(
      (k: string) =>
        !Object.keys(transformMap).includes(k) && !volSetNames.includes(k)
    );
    if (renegadeProperties.length) {
      console.warn(
        `A band of renegade properties was encountered in the asset with asset ID <<${transformedProperties.assetId}>>: ${renegadeProperties}`
      );
      renegadeProperties.forEach((prop) => {
        transformedProperties[prop] = featureObj.properties[prop];
      });
    }
    return transformedProperties;
  }

  private poke() {
    this.react.setState((prev) => ({ assets: [...prev.assets] }));
  }

  public static findById(id: string) {
    return this.react.getState().assets.find((asset) => asset.id === id);
  }

  public static connect(getState, setState) {
    this.react = { getState, setState };
  }

  public static connectMapbox(map) {
    this.mapbox = map;
  }

  public static setFocusedIds(focusedAssetIds: any[]): void {
    this.react.setState({ focusedAssetIds });
  }

  public static unfocusAll() {
    Asset.react.setState({ focusedAssetIds: [] });
  }

  public static resolveReactRegistrationQueue() {
    /*
      Adds a collection of incoming assets to the React state, removing
      apparent duplicates, because oh man is Mapbox happy to duplicate them.

      This is going to be necessary as long as assets are coming from Mapbox instead of from our own database...
      Otherwise, we'd just put them in the right place at load time, as we do with surveys and layers.
    */
    const oldAssets = this.react.getState().assets;
    const assetsById = oldAssets.reduce(
      (obj, asset) => ({ ...obj, [asset.id]: asset }),
      {}
    );
    Asset.registrationQueue.forEach((asset) => {
      assetsById[asset.id] = asset;
    });
    const assets = Object.values(assetsById);
    this.react.setState({ assets });
    Asset.registrationQueue = [];
  }
}

function transformTsonVolumeSet(volumeSet): IVolumeSet {
  const parsed = TSON.parse(volumeSet);
  return {
    label: parsed.LABEL,
    volume: parsed.VOL,
    mode: parsed.MODE,
  };
}
