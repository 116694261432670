import * as React from "react";

const MapStateContext = React.createContext();
const MapActionsContext = React.createContext();

export const mapStyles = [
  ["Dark", "mapbox://styles/crawfo10/ciw44q979003s2jl6u9f2vwwl"],
  ["Outdoors", "mapbox://styles/mapbox/outdoors-v12"],
  ["Satellite", "mapbox://styles/mapbox/satellite-streets-v12"],
];

export const MapProvider = ({ defaultStyle, children }) => {
  const styles = mapStyles.reduce((entries, [key, value]) => {
    entries[key] = value;
    return entries;
  }, {});

  const [state, setState] = React.useState({
    map: null,
    viewport: null,
    loaded: false,
    styles: styles,
    selectedStyle: defaultStyle || "Dark",
    zoom: 0,
  });

  const merge = (key) => (value) =>
    setState((prev) => ({ ...prev, [key]: value }));

  const actions = {
    setViewport: merge("viewport"),
    setMap: merge("map"),
    setLoaded: merge("loaded"),
    setZoom: merge("zoom"),
    setSelectedStyle: merge("selectedStyle"),
  };

  return (
    <MapStateContext.Provider value={state}>
      <MapActionsContext.Provider value={actions}>
        {children}
      </MapActionsContext.Provider>
    </MapStateContext.Provider>
  );
};

export const useMapState = () => {
  const context = React.useContext(MapStateContext);
  if (context === undefined) {
    throw new Error("Must 'useMapState' within a MapProvider.");
  }
  return context;
};

export const useMapActions = () => {
  const context = React.useContext(MapActionsContext);
  if (context === undefined) {
    throw new Error("Must 'useMapActions' within a MapProvider.");
  }
  return context;
};
