import * as React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import {
  Alignment,
  Button,
  Classes,
  Navbar,
  NavbarGroup,
  NavbarHeading,
} from "@blueprintjs/core";
import { FybrLogo } from "components/common";
import { useAuthState } from "context/auth";
import AuthenticatedHeader from "./AuthenticatedHeader";

const Header = () => {
  const { isAuthenticated } = useAuthState();

  return (
    <>
      <Navbar
        css={css`
          padding: 0.5rem 1rem;
          height: auto;
        `}
      >
        <NavbarGroup align={Alignment.LEFT}>
          <NavbarHeading>
            <FybrLogo />
          </NavbarHeading>
        </NavbarGroup>
        {isAuthenticated && <AuthenticatedHeader />}
      </Navbar>
    </>
  );
};

export default Header;
