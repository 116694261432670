import Annotation from "./Annotation";
import Asset from "./Asset";

export default class Layer {
  public class: typeof Layer;
  public assets: { [key: number]: any };
  public react: any;
  public mapbox: { map: any; ids: string[] | null };

  private _visible: boolean;
  [key: string]: any;

  public static react: any;
  public static mapbox: { map: any };
  public static editableTypes = ["annotation"];

  public constructor(attributes, getState?, setState?) {
    this.class = Layer;
    this.setAttributes(attributes, false);

    this.assets = {};

    this.react = {
      getState: getState || Layer.react.getState,
      setState: setState || Layer.react.setState,
    };

    const { surveys } = getState();

    const survey = surveys.find((survey) => survey.id === attributes.survey_id);

    this._visible = this.visible_by_default;
    this.mapbox = { map: null, ids: [] };

    if (survey) {
      const layer = survey.layers.find((layer) => layer.id === attributes.id);

      if (layer) {
        this._visible = layer._visible;
        this.mapbox = layer.mapbox;
      }
    }

    this.toggleVisible = this.toggleVisible.bind(this);
    this.setVisible = this.setVisible.bind(this);

    this.inspect = this.inspect.bind(this);
  }

  public get survey() {
    return this.react.getState().surveys.find((s) => s.id === this.survey_id);
  }
  public get visible() {
    return this._visible;
  }

  public get annotations(): Annotation[] {
    return this.react
      .getState()
      .annotations.filter((a) => a.layer_id === this.id && !a.archived)
      .sort((a, b) => a.id - b.id);
  }

  public get annotationsWithArchived(): Annotation[] {
    return this.react
      .getState()
      .annotations.filter((a) => a.layer_id === this.id);
  }
  public get assetsList(): Asset[] {
    return this.react
      .getState()
      .assets.filter((a) => a.features.some((f) => this.ownsFeature(f)));
  }

  public get hasAssets() {
    // Or... _Should_ have assets, based on some domain-oriented assumptions. In other words:
    // "Should we expect Mapbox to go get some Features with interesting Properties when this layer is loaded?"
    return ["Piles", "Decks", "Volumes", "Assets", "Packages"].includes(
      this.label
    );
  }

  // public get isDefaultAnnotationLayer() {
  //   return this.survey.defaultAnnotationLayerId === this.id;
  // }

  public get isEditable() {
    return Layer.editableTypes.includes(this.type) && this.editable;
  }

  public setAttributes(
    attributes: { [key: string]: any },
    poke: boolean = true
  ) {
    const apiDataWhitelist = [
      "id",
      "label",
      "survey_id",
      "source",
      "source_type",
      "source_layer",
      "source_id",
      "type",
      "vector_type",
      "legacy_type",
      "render_properties",
      "archived",
      "visible_by_default",
      "attributes",
      "editable",
      "published",
      "created_by",
    ];
    (Object as any).entries(attributes).forEach(([k, v]) => {
      if (apiDataWhitelist.includes(k)) {
        this[k] = v;

        /*
          `render_properties` should be nullable in Hickory,
          but in that case should be modeled as an empty
          object in Pimento.
        */

        if (k === "render_properties") {
          this[k] = v || {};
        }
      } else {
        console.warn(
          `Attempted to attach unknown attribute to layer model: <<${k}>>. If this is deliberate, it must be added to the data whitelist in the Layer model.`
        );
      }
    });

    if (this.attributes === null) {
      this.attributes = [];
    }
  }

  public inspect() {
    this.react.setState({ inspectorModel: this });
  }

  public toggleVisible(): void {
    this._visible = !this._visible;
    this.poke();
  }

  public setVisible(bool: boolean): void {
    this._visible = bool;
    this.poke();
  }

  public addAttributeLabel(attribute) {
    this.attributes.push({ id: attribute.id, label: attribute.label });
    this.poke();
  }

  public removeAttributeLabel(id) {
    this.attributes = this.attributes.filter(
      (attribute) => attribute.id !== id
    );
    this.poke();
  }

  public addAsset(feature): void {
    /*
      assetsList reads from React state, and so it might not include assets that
      were batch enqueued, so we need to check both lists for extant assets.
    */
    const extantAsset = [...this.assetsList, ...Asset.registrationQueue].find(
      (asset) => asset.id === feature.properties.AssetID
    );
    if (!extantAsset) {
      this.assets[feature.id] = new Asset(feature, this);
    } else {
      extantAsset.addFeature(feature);
    }
  }

  public getAsset(id: string) {
    const assets = this.assetsList.filter((a) => a.id === id);
    if (assets.length > 1) {
      throw new Error(`Duplicate asset with ID ${assets[0].id}`);
    }
    return assets[0];
  }
  public getAssetsByProperty(property, value): Asset[] {
    return this.assetsList.filter((a) => a.properties[property] === value);
  }

  public connectMapbox(mapboxId: string, map: any) {
    this.mapbox.map = Layer.mapbox.map;
    this.mapbox.ids.push(mapboxId);
    this.refreshMapboxLayers();
  }

  public ownsFeature(feature) {
    // TODO: `feature.layer.id` instead of `feature.source`? Maybe doesn't matter?
    return this.mapbox.ids.includes(feature.source);
  }

  public refreshMapboxLayers() {
    if (!this.mapbox.map) return;
    this.mapbox.ids = this.mapbox.ids.filter(
      (id) => !!this.mapbox.map.getLayer(id)
    );
  }

  // Perturbs the collection to trigger a re-render. If this becomes a performance bottleneck, we'll need to
  // find a way to perturb a single item instead of an entire collection.
  private poke(): void {
    Layer.react.setState((prev) => ({ layers: [...prev.layers] }));
  }

  public static poke(): void {
    this.react.setState((prev) => ({ layers: [...prev.layers] }));
  }

  public static find(id: number): Layer | undefined {
    return Layer.react.getState().layers.find((l) => l.id === id);
  }

  public static connect(getState, setState): void {
    this.react = { getState, setState };
  }

  public static connectMapbox(map): void {
    this.mapbox = this.mapbox || { map: null };
    this.mapbox.map = map;
  }

  public static resetAllVisibilities(): void {
    this.react
      .getState()
      .layers.forEach((layer) => layer.setVisible(layer.visible_by_default));
  }

  public static refreshMapboxLayers(): void {
    const layers = this.react.getState().layers;
    layers.forEach((l) => l.refreshMapboxLayers());
  }

  public static linkFeatures(surveyId): void {
    /*
    Called whenever we think that the Mapbox feature set has changed. In practice, this is on the map.on('data') event when the event.isTheWholeThingLoaded flag or whatever is true.
    Procedure:
      Make sure every Pimento Layer only contains IDs that exist
      Ask Mapbox to queryRenderedFeatures, filtered by those IDs (so we get like ~20 features to flip through, instead of ~2000)
        For each one of those features:
            Find the Pimento Layer that owns it
            Tell that Pimento Layer to arrange for one of its Pimento Assets to integrate that feature
        Perturb the collection of Layers so that all of the React components tumble properly
    */
    Layer.refreshMapboxLayers();
    const layers = this.react
      .getState()
      .layers.filter((l) => l.survey_id === surveyId);
    const mapboxLayerIds = layers
      .filter((l) => l && l.type === "annotation")
      .reduce((acc, next) => [...acc, ...next.mapbox.ids], []);
    const features = this.mapbox.map.queryRenderedFeatures(undefined, {
      layers: mapboxLayerIds,
    });
    features.forEach((f) => {
      const parentLayer = layers.find((l) => l.ownsFeature(f));
      if (!parentLayer) {
        console.warn(
          `Could not find layer for feature with AssetID <<${f.properties.AssetID}>>`
        );
      } else {
        parentLayer.addAsset(f);
      }
    });
    Asset.resolveReactRegistrationQueue();
  }
}
