import * as React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { css } from "@emotion/core";

import Authenticate from "./Auth";
import Header from "./Portal/Header";

import { useAuthState } from "context/auth";
import {
  AccessProvider,
  SinkProvider,
  SupportProvider,
  UnitProvider,
} from "context";
import { Loading } from "components/common/";
import Support from "components/User/Support";

const Surveys = React.lazy(() => import("./Surveys"));

const App = () => {
  const { isPendingAuthentication, isAuthenticated } = useAuthState();

  const { pathname } = useLocation();

  return (
    <main
      css={css`
        display: flex;
        flex-direction: column;
        height: 100%;
        background: var(--background-gradient);
      `}
      className="bp3-dark"
    >
      {isPendingAuthentication == false &&
        (isAuthenticated === false ? (
          <>
            <Header />
            <Switch>
              <Route path="/resetpassword" component={Authenticate} />
              <Route path="/login" component={Authenticate} />
              <Redirect to={`/login?redirect=${pathname}`} />
            </Switch>
          </>
        ) : (
          <SinkProvider>
            <AccessProvider>
              <UnitProvider>
                <SupportProvider>
                  <Header />
                  <React.Suspense fallback={<Loading />}>
                    <Surveys />
                  </React.Suspense>
                  <Switch>
                    <Route path="/surveys/:survey_id" component={Support} />
                    <Route path="/(.*)" component={Support} />
                  </Switch>
                </SupportProvider>
              </UnitProvider>
            </AccessProvider>
          </SinkProvider>
        ))}
    </main>
  );
};

export default App;
