import { css } from "@emotion/core";
import * as React from "react";
import { Redirect, Route } from "react-router-dom";
import { Card } from "@blueprintjs/core";

import { FybrLogo } from "../common";

import Login from "./Login";
import ResetPassword from "./ResetPassword";

const Authenticate = (props) => {
  return (
    <div
      css={css`
        flex-grow: 1;
        display: flex;
        background: var(--background-gradient);
        justify-content: center;
        align-items: center;
      `}
    >
      <Card
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 4rem 3rem 2rem 3rem;
        `}
      >
        <header
          css={css`
            margin-bottom: 3rem;
          `}
        >
          <FybrLogo size={128} dark />
        </header>
        <main
          css={css`
            min-width: 360px;
          `}
        >
          <Route path="/login" component={Login} />
          <Route path="/resetpassword" component={ResetPassword} />
        </main>
      </Card>
    </div>
  );
};

export default Authenticate;
